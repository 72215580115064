import { Space } from "./spaces-interface";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IInitState } from "../root-interface";
import { ALL_SPACES, GET_CHILDREN_SPACE } from "../config";
import { setToken } from "../userInfo/userInfo-reducer";

export const loadSpaces = createAsyncThunk<
  Space[],
  undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@spaces/loadSpaces",
  async function (_, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;
    const header = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + userState.token,
        responseType: "json",
      },
    };

    const response = await fetch(ALL_SPACES(), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
        if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as Space[];
    }
  }
);

export const loadSingleSpace = createAsyncThunk<
  Space,
  number,
  { getState: IInitState; rejectValue: string }
>(
  "@@spaces/loadSingleSpaces",
  async function (_, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;
    const header = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + userState.token,
        responseType: "json",
      },
    };
    const response = await fetch(GET_CHILDREN_SPACE(), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
        if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as Space;
    }
  }
);

export const getSpace = (state: Space[], id: number) => {
  return state.filter((stateItem: Space) => stateItem.id == id)[0];
};
