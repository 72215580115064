import s from "../Questionnaire.module.css";

import { Accordion, Button, Htag } from "components";
import {
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
} from "@mui/material";

import { addId, removeId } from "store/system_tickets/system_ticket-reducer";
import { useState } from "react";
import { QuestionnaireProps } from "../Questionnaire.props";
import { useAppDispatch, useAppSelector } from "utils/hooks";

const desc = `<p>The law now recognises that (absent any risk of harm) children benefit from having both parents taking an active role in their lives.</p>
    <p>
     This means that the starting point when working out arrangements for your children is that you and your ex will share their care. Shared care doesn’t necessarily mean your kids will share their time equally between you.
    </p>
    <p>
    What the arrangements for children actually look like will be different for every family. If you haven’t agreed long term arrangements yet, we'll help you to think about ways to craft your family arrangements in a way that best fits your family.
    </p>`;

export const FormCB3 = ({
  handleBack,
  setData,
  handleNextStep,
}: QuestionnaireProps): JSX.Element => {
  const childrenOnboarding = useAppSelector(
    (state) => state.questionnairesState.cQuestionnaire
  );
  const [value, setValue] = useState(childrenOnboarding.agreed_arrangements);
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");

  const living_property = useAppSelector(
    (state) =>
      state.userState.user.questionnaires.generalQuestionnary?.living_property
  );

  const dispatch = useAppDispatch();

  const updateInterview = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = ev.target;
    if (value === "no") {
      setValue(value);
      dispatch(addId(["C6"]));

      if (living_property === "same") {
        dispatch(addId(["C5a"]));
      } else if (living_property === "different") {
        dispatch(addId(["C5b"]));
      }
    } else if (value === "temporarily") {
      setValue(value);
      dispatch(addId(["C6"]));
      dispatch(removeId(["C5a", "C5b"]));
    } else if (value === "yes") {
      setValue(value);
      dispatch(removeId(["C5a", "C5b", "C6"]));
    }

    setHelperText(" ");
    setError(false);

    setData?.(name, value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (value) {
      setHelperText("");
      handleNextStep?.();
      setError(false);
    } else {
      setHelperText("Please select an option.");
      setError(true);
    }
  };

  return (
    <form id="form-3" onSubmit={handleSubmit} className={s.form}>
      <span className={s.stepMark}>3</span>
      <Htag tag="h3">
        Have you already agreed arrangements for how your children will spend
        their time between you and your ex?
      </Htag>
      <div className={s.form_oneline}>
        <div className={s.formField}>
          <RadioGroup
            row
            aria-labelledby="demo-form-control-label-placement"
            name="agreed_arrangements"
            className={s.radioboxes}
          >
            <FormControlLabel
              control={
                <Radio
                  color="default"
                  checked={childrenOnboarding.agreed_arrangements === "yes"}
                  onChange={updateInterview}
                />
              }
              label="Yes."
              value="yes"
            />
            <FormControlLabel
              value="no"
              control={
                <Radio
                  color="default"
                  checked={childrenOnboarding.agreed_arrangements == "no"}
                  onChange={updateInterview}
                />
              }
              label="No."
            />
            <FormControlLabel
              label="Temporarily - but we need to put something permanent in place."
              value="temporarily"
              control={
                <Radio
                  color="default"
                  checked={
                    childrenOnboarding.agreed_arrangements === "temporarily"
                  }
                  onChange={updateInterview}
                />
              }
            />
          </RadioGroup>
          {error && (
            <FormHelperText className={s.error}>{helperText}</FormHelperText>
          )}
        </div>
      </div>

      <Accordion
        className={s.desc}
        accordionData={[
          {
            title: `Why are we asking?`,
            content: desc,
          },
        ]}
      />

      <div className={s.groupButton}>
        <Button
          onClick={() => handleBack()}
          apearance="default"
          className={s.goBack}
          type="button"
        >
          Previous
        </Button>
        <Button type="submit" apearance="secondary">
          Next
        </Button>
      </div>
    </form>
  );
};
