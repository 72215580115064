import s from "./EditFeeling.module.css";

import { useParams } from "react-router-dom";
import { Button, ErrorField, Htag } from "../../../../components";
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks";
import { Feelling } from "../../../../store/challenges/challenges.interface";
import { useEffect, useRef, useState } from "react";

import { updateFeeling } from "../../../../store/challenges/challenges-thunk";
import { Alert, Snackbar } from "@mui/material";

import { EditorComponent } from "../../../../components/Editor/Editor.component";
export const EditFeeling = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const feeling: Feelling = useAppSelector((state) =>
    state.wellbeing.feellings.feelings.filter(
      (feeling: Feelling) => feeling.id == id
    )
  )[0];
  const [serverError, setServerError] = useState("");

  const [convertedContent, setConvertedContent] = useState("");
  const [open, setOpen] = useState(false);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  //   console.log(error);
  useEffect(() => {
    setConvertedContent(feeling.description);
  }, [feeling]);

  const save = () => {
    if (!id) return;
    dispatch(
      updateFeeling({ feeling_id: Number(id), description: convertedContent })
    )
      .unwrap()
      .then(() => {
        setOpen(true);
      })
      .catch((error) => {
        console.log(error);
        setServerError(
          "Can't be saved, some problem with server. Please try later"
        );
      });
  };
  const handleChange = (content: string) => {
    setConvertedContent(content);
    setServerError("");
  };
  return (
    <>
      <Htag tag="h3">Edit description of emotion "{feeling.title}" </Htag>
      <div className={s.wyswyg_editorWrap}>
        <EditorComponent value={convertedContent} handleChange={handleChange} />
      </div>
      {serverError && <ErrorField error={serverError} />}
      <Button apearance="secondary" onClick={save}>
        Save
      </Button>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Description is updated!
        </Alert>
      </Snackbar>
    </>
  );
};
