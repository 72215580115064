import { NavLink } from "react-router-dom";
import styles from "./Footer.module.css";
import { FooterProps } from "./Footer.props";
import cn from "classnames";
import { Insta, LinkedIn } from "./Footer.images";
import { FullModalStyled, Htag, LogoText } from "components";
import { useLayoutEffect, useState } from "react";
import ReactHtmlParser from "html-react-parser";
import TermlyScriptWrapper from "./Termly.component";
import { OnBoardingComponent } from "layout/auth/Registration/OnBoarding.component";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { setInterviewToInit, setRegModalStatus } from 'store/onBoarding/onboarding-reducer';
interface IFMenu {
  headling: string;
  colorStyle: string;
  pages: {
    url: string;
    value: string | JSX.Element;
  }[];
}

export const Footer = ({ ...props }: FooterProps): JSX.Element => {
  const [footerMenu, setFooterMenu] = useState<IFMenu[]>([]);
  const dispatch = useAppDispatch();
  const regModalStatus = useAppSelector(
    (state) => state.onRegistration.openModal
  );
  const handleCloseReg = () => {
    dispatch(setInterviewToInit());
    dispatch(setRegModalStatus(false));
  };
  useLayoutEffect(() => {
    setFooterMenu([
      {
        headling: "About Us",
        colorStyle: "#DEAD7F",

        pages: [
          {
            url: "/how-it-works",
            value: "How it works",
          },
          {
            url: "/team",
            value: "About us",
          },
        ],
      },
      {
        headling: "Useful information",
        colorStyle: "#4EB072",

        pages: [
          {
            url: "/pricing",
            value: "Pricing",
          },
          {
            url: "/features",
            value: "Features",
          },

          {
            url: "/faq",
            value: "FAQ",
          },
          {
            url: "/domestic-abuse",
            value: "Help for domestic abuse",
          },
        ],
      },
      {
        headling: "Contact",
        colorStyle: "#DEAD7F",
        pages: [
          {
            url: "/contact",
            value: "Ask a question",
          },
        ],
      },
      {
        headling: "Join our community",
        colorStyle: "#0999B9",
        pages: [
          {
            url: "https://www.instagram.com/separatespace/?igshid=ZDdkNTZiNTM%3D",
            value: <Insta />,
          },
          {
            url: "https://uk.linkedin.com/in/amanda-bell-57044733 ",
            value: <LinkedIn />,
          },
        ],
      },
    ]);
  }, []);

  return (
    <footer className={cn(styles.footer)} {...props}>
      <div className={styles.wrapper}>
        <div className={styles.aboutUs}>
          <LogoText className={styles.footer_logo} />
          <p>
            SeparateSpace Ltd is not a law firm and is not regulated by the
            Solicitors Regulation Authority. No lawyer-client relationship is
            created between SeparateSpace and its subscribers.
          </p>
          <p>
            SeparateSpace Ltd is a private limited company incorporated in
            England and Wales with registration number 14122229 and with its
            registered office at 167-169 Great Portland Street, London W1W 5PF,
            United Kingdom.
          </p>
        </div>
        {footerMenu.map((item: IFMenu, i: number) => (
          <div
            className={cn(styles.footerMenu, {
              [styles.aboutUsLink]: i + 1 === 1,
              [styles.contact]: i + 1 === 3,
              [styles.useful]: i + 1 === 2,
              [styles.join]: i + 1 === 4,
            })}
            key={i}
          >
            <Htag tag="h4" color={item.colorStyle}>
              {item.headling}
            </Htag>
            <ul>
              {item.pages.map((subitem, i) => (
                <li key={i}>
                  <a href={subitem.url}>{subitem.value}</a>
                </li>
              ))}
            </ul>
          </div>
        ))}

        <div className={styles.copyBlock}>
          &copy; SeparateSpace {new Date().getFullYear()}
        </div>
        <div className={styles.termsLink} id="termsLink">
          <NavLink
            className={({ isActive }) =>
              isActive ? styles.active : styles.inactive
            }
            to="/privacy-policy"
          >
            Privacy policy
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive ? styles.active : styles.inactive
            }
            to="/terms-of-service"
          >
            Terms of service
          </NavLink>

          <NavLink
            className={({ isActive }) =>
              isActive ? styles.active : styles.inactive
            }
            to="/Cookies-Policy"
          >
            Cookie policy
          </NavLink>
        </div>
      </div>
      <FullModalStyled
        color="light"
        open={regModalStatus}
        close={handleCloseReg}
      >
        <OnBoardingComponent />
      </FullModalStyled>
    </footer>
  );
};
