import { ISystemResource } from '../system_resources/system_resource.interface';
import { ITicket } from '../tickets/tickets-interface';

export const milestones = [
  {
    value: 1,
    label: "Make interim arrangements",
  },
  {
    value: 2,
    label: "Start the legal divorce process",
  },
  {
    value: 3,
    label: "Untangle the finances",
  },
  {
    value: 4,
    label: "Formalise your agreements",
  },
  {
    value: 5,
    label: "When you're divorced",
  },
];

export interface Milestone {
  id: number;
  title: string;
  complete_percent: number;
  resources: ISystemResource[];
  sort: number;
  tickets: ITicket[];
}
