import { useEffect } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  Box,
  IconButton,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import { Edit } from "@mui/icons-material";

import s from "../SpecialSources.module.css";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import {  Htag } from "components";
import dayjs from "dayjs";
import {  get_all_special_cs } from "store/caseStudy/caseStudy_thunk";
import { ICaseStudies } from "store/caseStudy/caseStudy.inderface";



export const ListSpecialCaseStudies = () => {
  const dispatch = useAppDispatch();

  const case_studies: ICaseStudies[] = useAppSelector(
    (state) => state.caseStudiesState.caseStudiesArray
  );

  const copy = async (id: string, space_id: string) => {
    await navigator.clipboard.writeText(
      `${process.env.REACT_APP_BASE_URL}/dashboard/space/${space_id}/resources/${id}`
    );
  };
  useEffect(() => {
    dispatch(get_all_special_cs());
  }, [dispatch]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      headerClassName: s.headerClassName,
      width: 70,
      sortable: false,
      disableColumnMenu: true,
    },

    {
      field: "title",
      headerClassName: s.headerClassName,
      headerName: "Title",
      sortable: true,
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "unique_number",
      headerClassName: s.headerClassName,
      headerName: "Unique Number",
      sortable: true,
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "created_at",
      headerName: "Create Date",
      headerClassName: s.headerClassName,
      flex: 1,
      sortable: true,
      disableColumnMenu: true,
      renderCell: (params) => {
        return <>{dayjs(params.row.created_at).format("MMMM DD, YYYY")}</>;
      },
    },
    {
      field: "action",
      headerName: `Action`,
      headerClassName: s.headerClassName,
      sortable: false,
      width: 160,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <div className={s.actions}>
            <NavLink
              to={`/admin-dashboard/caseStudies/edit-page/${params.row.id}`}
            >
              <IconButton aria-label="edit" color="info">
                <Edit />
              </IconButton>
            </NavLink>

            {/* <ContentCopyIcon
              className={s.externalLink}
              onClick={() =>
                copy(params.id.toString(), params.row.space_id.toString())
              }
            /> */}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Htag tag="h3">List of case studies</Htag>
      <Box sx={{ height: 838, width: "100%" }}>
        <DataGrid
          rows={case_studies}
          columns={columns}
    
          className={s.tableWrap}
 
        />
      </Box>
  
    </>
  );
};
