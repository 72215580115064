import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  CHANGE_PASS,
  DELETE_PROFILE,
  EMAIL_SUBSCRIPTION,
  GET_USER_DATA,
  LOGIN,
  UPDATE_PROFILE,
} from "../config";

import { IInitState } from "../root-interface";
import { IOUserEntity, IUser, ProfileUpdate } from "./user-interface";
import { ILoginForm } from "../login/login-interface";
import { setToken } from "./userInfo-reducer";

export const setUser = createAsyncThunk<
  IOUserEntity,
  ILoginForm,
  { getState: IInitState; rejectValue: string }
>("@@login/loginUser", async function (initLogin, { rejectWithValue }) {
  const header = {
    method: "POST",
    headers: {
      Accept: "application/json",
      responseType: "json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(initLogin),
  };

  const response = await fetch(LOGIN(), header);

  if (!response.ok) {
    return response
      .text()
      .then((result) => JSON.parse(result))
      .then((result) => {
        return rejectWithValue(result.message);
      });
  } else {
    return (await response.json()) as IOUserEntity;
  }
});

export const changePassThunk = createAsyncThunk<
  { message: string },
  {
    old_password: string;
    password: string;
    password_confirmation: string;
  },
  { getState: IInitState; rejectValue: string }
>("@@user/changePass", async function (data, { getState, rejectWithValue }) {
  const { userState } = getState() as IInitState;

  const header = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + userState.token,
      responseType: "json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };

  const response = await fetch(CHANGE_PASS(), header);

  if (!response.ok) {
    return response
      .text()
      .then((result) => JSON.parse(result))
      .then((result) => {
        if (result.errors) {
          return rejectWithValue(result.errors.code[0]);
        } else {
          return rejectWithValue(result.error);
        }
      });
  } else {
    return (await response.json()) as { message: string };
  }
});

export const getUserData = createAsyncThunk<
  { user: IUser },
  undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@user/getUserData",
  async function (_, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;
    const header = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + userState.token,
        responseType: "json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    };
    const response = await fetch(GET_USER_DATA(), header);
    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
        if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          if (result.errors) {
            return rejectWithValue(result.errors.code[0]);
          } else {
            return rejectWithValue(result.error);
          }
        });
    } else {
      return (await response.json()) as { user: IUser };
    }
  }
);

export const updateProfile = createAsyncThunk<
  IUser,
  Partial<ProfileUpdate>,
  { getState: IInitState; rejectValue: string }
>(
  "@@user/updateProfile",
  async function (data, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + userState.token,
        responseType: "json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(UPDATE_PROFILE(), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
        if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          if (result.errors) {
            return rejectWithValue(result.errors.code[0]);
          } else {
            return rejectWithValue(result.error);
          }
        });
    } else {
      return (await response.json()) as IUser;
    }
  }
);

export const deleteProfile = createAsyncThunk<
  { success: string },
  undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@user/deleteProfile",
  async function (_, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + userState.token,
        responseType: "json",
        "Content-Type": "application/json",
      },
    };

    const response = await fetch(DELETE_PROFILE(), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
        if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          if (result.errors) {
            return rejectWithValue(result.errors.code[0]);
          } else {
            return rejectWithValue(result.error);
          }
        });
    } else {
      return (await response.json()) as { success: string };
    }
  }
);

export const updateEmailSubscription = createAsyncThunk<
  { subscription: boolean; success: string },
  { subscription: boolean },
  { getState: IInitState; rejectValue: string }
>(
  "@@user/updateEmailSubscription",
  async function (data, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + userState.token,
        responseType: "json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(EMAIL_SUBSCRIPTION(), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
        if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          if (result.errors) {
            return rejectWithValue(result.errors.code[0]);
          } else {
            return rejectWithValue(result.error);
          }
        });
    } else {
      return (await response.json()) as {
        subscription: boolean;
        success: string;
      };
    }
  }
);
