import { Outlet, useNavigate } from "react-router-dom";

import { DashboardLayoutProps } from "./DashboardLayout.props";
import s from "./DashboardLayout.module.css";

import { IconButton } from "@mui/material";

import cn from "classnames";
import MenuIcon from "@mui/icons-material/Menu";
import { useEffect, useState } from "react";

import {
  useAppDispatch,
  useAppSelector,
  useWindowScrollPositions,
} from "utils/hooks";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { GoToTop, ModalStyled, TopBar } from "components";

import { ForgotPass } from "./auth/ForgotPass/ForgotPass.component";
import {
  openLoginModal,
  openPassModal,
  resetToDefault,
} from "store/login/login-reducer";
import { Login } from "./auth/Login/Login.component";
import Sidebar from "./Sidebar/Sidebar.component";
import { Footer } from "layout";

export const AdminDashboardLayout = (
  props: DashboardLayoutProps
): JSX.Element => {
  const [mobileNavOpen, setMobileNavOpen] = useState<boolean>(false);
  const token = useAppSelector((state) => state.userState.token);
  const expired_date = useAppSelector((state) => state.userState.expired_at);
  const loginModalStatus = useAppSelector(
    (state) => state.onLogin.openLoginModal
  );
  const passwordModalStatus = useAppSelector(
    (state) => state.onLogin.openPassModal
  );

  const { scrollY } = useWindowScrollPositions();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  dayjs.extend(relativeTime);

  useEffect(() => {
    if (expired_date) {
      const diference = dayjs(expired_date).diff(dayjs(Date.now()));

      if (diference !== undefined && diference < 0) {
        dispatch(openLoginModal(true));
        localStorage.removeItem("userToken");
        dispatch(resetToDefault());
      } else {
        const sInterval = setInterval(() => {
          dispatch(openLoginModal(true));
          localStorage.removeItem("userToken");
          dispatch(resetToDefault());
          clearInterval(sInterval);
        }, diference);
      }
    }
  }, [dispatch]);

  const handleCloseLogout = () => {
    dispatch(openLoginModal(false));
    localStorage.removeItem("userToken");
    dispatch(resetToDefault());
    navigate("/");
  };

  const handleClosePass = () => {
    dispatch(openPassModal(false));
  };

  const handleDrawerToggle = () => {
    setMobileNavOpen(!mobileNavOpen);
  };

  useEffect(() => {
    if (!token) dispatch(openLoginModal(true));
  }, [token]);

  return (
    <div className={s.adminDashboardWrap} {...props}>
      <Sidebar mobileopen={mobileNavOpen} closenav={handleDrawerToggle} />
      <div className={s.dashboardContent}>
        <TopBar
          className={cn(s.dashboardBar, {
            [s.sticky]: scrollY > 100,
          })}
        >
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => setMobileNavOpen(!mobileNavOpen)}
          >
            <MenuIcon />
          </IconButton>
        </TopBar>
        <Outlet />
      </div>
      <Footer />

      <ModalStyled open={loginModalStatus} close={handleCloseLogout}>
        <Login title="You have been signed out." />
      </ModalStyled>
      <ModalStyled open={passwordModalStatus} size="m" close={handleClosePass}>
        <ForgotPass />
      </ModalStyled>
      <GoToTop />
    </div>
  );
};
