import { createAsyncThunk } from "@reduxjs/toolkit";
import { IInitState } from "../root-interface";
import {
  CANCEL_STRIPE,
  REFUNDS_LIST_STRIPE,
  REFUND_DELETE_STRIPE,
  REFUND_RELOAD_STRIPE,
  REFUND_REQUEST_STRIPE,
  RESUME_STRIPE,
} from "../config";
import { setToken } from "../userInfo/userInfo-reducer";
import { IRequstRefundListData } from "store/waitingList/waitingList.interface";

export const pauseSubscription = createAsyncThunk<
  { success: string },
  undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@stripe/pause",
  async function (_, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "POST",
      headers: {
        Accept: "application/json",
        responseType: "json",
        Authorization: "Bearer " + userState.token,
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(CANCEL_STRIPE(), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
        if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as { success: string };
    }
  }
);

export const resumeSubscription = createAsyncThunk<
  { success: string },
  undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@stripe/resume",
  async function (_, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "POST",
      headers: {
        Accept: "application/json",
        responseType: "json",
        Authorization: "Bearer " + userState.token,
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(RESUME_STRIPE(), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
        if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as { success: string };
    }
  }
);

export const refundSubscriptionRequest = createAsyncThunk<
  { success: string },
  undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@stripe/refund",
  async function (_, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "POST",
      headers: {
        Accept: "application/json",
        responseType: "json",
        Authorization: "Bearer " + userState.token,
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(REFUND_REQUEST_STRIPE(), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
        if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as { success: string };
    }
  }
);

export const refundRequestList = createAsyncThunk<
  IRequstRefundListData,
  { page: number; limit: number },
  { getState: IInitState; rejectValue: string }
>(
  "@@stripe/refundList",
  async function (data, { getState, rejectWithValue, dispatch }) {
    const { page, limit } = data;
    const { userState } = getState() as IInitState;

    const header = {
      method: "GET",
      headers: {
        Accept: "application/json",
        responseType: "json",
        Authorization: "Bearer " + userState.token,
      },
    };
    const response = await fetch(REFUNDS_LIST_STRIPE(page, limit), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
        if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as IRequstRefundListData;
    }
  }
);

export const refundResponse = createAsyncThunk<
  { success: string },
  { id: string; customer: string },
  { getState: IInitState; rejectValue: string }
>(
  "@@stripe/refundresponse",
  async function ({ id, customer }, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "POST",
      headers: {
        Accept: "application/json",
        responseType: "json",
        Authorization: "Bearer " + userState.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        customer: customer,
      }),
    };
    const response = await fetch(REFUND_RELOAD_STRIPE(id), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
        if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as { success: string };
    }
  }
);

export const refundDelete = createAsyncThunk<
  { success: string },
 string,
  { getState: IInitState; rejectValue: string }
>(
  "@@stripe/refundDelete",
  async function (id, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        responseType: "json",
        Authorization: "Bearer " + userState.token,
        "Content-Type": "application/json",
      },
     
    };
    const response = await fetch(REFUND_DELETE_STRIPE(id), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
        if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as { success: string };
    }
  }
);
