import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ADD_SYSTEM_TICKETS,
  GET_SINGLE_SYSTEM_TICKET,
  GET_SYSTEM_TICKETS,
  UPDATE_SYSTEM_TICKET,
} from "../config";
import { IInitState } from "../root-interface";
import { ISystemTicket } from "./system_ticket.interface";
import { setToken } from "../userInfo/userInfo-reducer";

export const add_system_tickets = createAsyncThunk<
  { success: string },
  string[],
  { getState: IInitState; rejectValue: string }
>(
  "@@systemtickets/adding",
  async function (unique_numbers, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "POST",
      headers: {
        Accept: "application/json",
        responseType: "json",
        Authorization: "Bearer " + userState.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ unique_numbers: unique_numbers }),
    };
    const response = await fetch(ADD_SYSTEM_TICKETS(), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
        if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as { success: string };
    }
  }
);

export const get_system_tickets = createAsyncThunk<
  ISystemTicket[],
  undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@systemtickets/getting",
  async function (_, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "GET",
      headers: {
        Accept: "application/json",
        responseType: "json",
        Authorization: "Bearer " + userState.token,
      },
    };
    const response = await fetch(GET_SYSTEM_TICKETS(), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
        if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as ISystemTicket[];
    }
  }
);

export const get_system_single_ticket = createAsyncThunk<
  ISystemTicket,
  number,
  { getState: IInitState; rejectValue: string }
>(
  "@@systemtickets/getSingleTicket",
  async function (id, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "GET",
      headers: {
        Accept: "application/json",
        responseType: "json",
        Authorization: "Bearer " + userState.token,
      },
    };
    const response = await fetch(GET_SINGLE_SYSTEM_TICKET(id), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
        if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as ISystemTicket;
    }
  }
);

export const update_system_ticket = createAsyncThunk<
  { success: string; special_ticket: ISystemTicket },
  {
    id: string;
    data: {
      title: string;
      description: string;
      milestone_id?: number;
      attachments: any[];
      reading_time: string;
    };
  },
  { getState: IInitState; rejectValue: string }
>(
  "@@systemtickets/updateTicket",
  async function ({ id, data }, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;
    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("description", data.description);
    formData.append("reading_time", data.reading_time);
    if (data.milestone_id)
      formData.append("milestone_id", data.milestone_id.toString());
    data.attachments.map((file) => {
      //   formData.append("attachments[]", file);
      formData.append("attachments[][file]", file.file);
      formData.append("attachments[][type]", file.type);
    });
    const header = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + userState.token,
      },
      body: formData,
    };
    const response = await fetch(UPDATE_SYSTEM_TICKET(id), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
        if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          return rejectWithValue(result.errors);
        });
    } else {
      return (await response.json()) as {
        success: string;
        special_ticket: ISystemTicket;
      };
    }
  }
);
