import { createAsyncThunk } from "@reduxjs/toolkit";
import { SB_GET, SB_UPDATE } from "store/config";
import { IInitState } from "store/root-interface";

export type Banner = {
  visible: boolean;
  text: string;
  url: string;
};

export const getSB = createAsyncThunk<
  Banner,
  string | null | undefined,
  { getState: IInitState; rejectValue: string }
>("@@widgets/banner", async function (_, { rejectWithValue }) {
  const header = {
    method: "GET",
    headers: {
      Accept: "application/json",
      responseType: "json",
    //   "Content-Type": "application/json",
    },
  };
  const response = await fetch(SB_GET(), header);

  if (!response.ok) {
    return response
      .text()
      .then((result) => JSON.parse(result))
      .then((result) => {
        return rejectWithValue(result.message);
      });
  } else {

    return (await response.json()) as Banner;
  }
});

export const setSB = createAsyncThunk<
  any,
  any,
  { getState: IInitState; rejectValue: string }
>(
  "@@widgets/banner_set",
  async function (banner_data, { getState, rejectWithValue }) {
    const { userState } = getState() as IInitState;
    const header = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + userState.token,
        responseType: "json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(banner_data),
    };
    const response = await fetch(SB_UPDATE(), header);

    if (!response.ok) {
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          return rejectWithValue(result.errors);
        });
    } else {
      return (await response.json()).data as Banner;
    }
  }
);
