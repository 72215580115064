import { Chip, List, ListItem } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { Card, ClockIcon, NoImgIcon } from "components";

import s from "./Resource.module.css";
import cn from "classnames";

import { ISystemResource } from "store/system_resources/system_resource.interface";
import { BookmarkItem } from "store/bookmarks/bookmark-interface";
import { useEffect, useState } from "react";
import { getArticleLink } from "controlls/use-link";

interface IResourcesList {
  resources: ISystemResource[];
  show_in_row: number;
  showTgas: boolean;
}

type resourceArticleType = ISystemResource | BookmarkItem;

export const ResourcesListComponent = ({
  resources,
  show_in_row,
  showTgas,
}: IResourcesList) => {
  const navigate = useNavigate();
  const [tags, setTags] = useState<string[]>([]);
  const [resourceList, setResourceList] =
    useState<ISystemResource[]>(resources);
  const getRightId = (item: resourceArticleType, type?: string) => {
    const id = item.resource_id ? item.resource_id : item.id;

    if (type) {
      return getArticleLink(type, Number(id));
    } else {
      return `/dashboard/resources/${
        item.resource_id ? item.resource_id : item.id
      }`;
    }
  };

  const btnText = ["See all guides", "See less guides"];
  const [activeTag, setActiveTag] = useState("");
  const [btnState, setBtnState] = useState(0);

  const [showIndex, setShowIndex] = useState(show_in_row);

  useEffect(() => {
    setResourceList(resources);
  }, [resources]);

  useEffect(() => {
    if (!resources) return;
    const tags = resources
      .reduce(
        (accumulator: any[], currentValue) =>
          currentValue.tags
            ? [...accumulator, ...currentValue.tags]
            : accumulator,
        []
      )
      .filter(function (value, index, array) {
        return array.indexOf(value) === index;
      });
    if (tags) setTags(tags);
  }, [resources]);

  const filterSourcesByTag = (tag: string) => {
    if (!resources) return;
    setActiveTag(tag);

    if (tag == "") {
      setResourceList(resources);
      return;
    }
    const filteredResources = resources.filter(
      (item) => item.tags && item.tags.find((el) => el == tag)
    );

    setResourceList(filteredResources);
  };

  const buttonStyle = () => {
    if (btnState == 0) {
      setBtnState(1);
      setShowIndex(resourceList.length);
    } else {
      setBtnState(0);
      setShowIndex(show_in_row);
    }
  };

  return (
    <>
      {showTgas && (
        <div className={s.filterWrap}>
          <div className={s.tagsList}>
            <Chip
              className={s.tag}
              label={"All"}
              variant="outlined"
              onClick={() => filterSourcesByTag("")}
            />
            {tags.map((tag, index) => (
              <Chip
                className={cn(s.tag, {
                  [s.activeTag]: tag == activeTag,
                })}
                key={index}
                label={tag}
                variant="outlined"
                onClick={() => filterSourcesByTag(tag)}
              />
            ))}
          </div>

          <span
            className={cn(
              {
                [s.showBtn]: resourceList.length > showIndex || btnState == 1,
                [s.hideBtn]: resourceList.length < showIndex,
              },
              "hover-underline-animation_back"
            )}
            onClick={buttonStyle}
          >
            {btnText[btnState]}
          </span>
        </div>
      )}
      <div className={s.mobile_scroll_container}>
        <List
          className={s.resourceList}
          style={{
            gridTemplateColumns: `repeat( ${show_in_row}, 1fr)`,
          }}
        >
          {resourceList
            ?.slice(0, showIndex)
            .map((resource: ISystemResource) => (
              <ListItem
                key={`${resource.id}${Math.random()}`}
                className={s.listItem}
              >
                <Card
                  className={s.resource_card}
                  onClick={() =>
                    navigate(`${getRightId(resource, resource.type)}`)
                  }
                >
                  <div className={cn(s.resource_preview)}>
                    {resource.image && typeof resource.image == "string" ? (
                      <img src={resource.image} alt="" />
                    ) : (
                      <NoImgIcon />
                    )}
                  </div>
                  <div className={s.resource_meta}>
                    <p> {resource.title}</p>
                    <div className={s.resource_meta_extra}>
                      <Chip
                        label="Guide"
                        variant="outlined"
                        size="medium"
                        className={s.chip}
                      />
                      {resource.reading_time && (
                        <span className={s.resource_meta_readingTime}>
                          <ClockIcon /> {resource.reading_time}
                        </span>
                      )}
                    </div>
                  </div>
                </Card>
              </ListItem>
            ))}
        </List>
      </div>
    </>
  );
};
