import {
  Alert,
  Checkbox,
  FormControl,
  FormControlLabel,
  Snackbar,
} from "@mui/material";

import { useEffect, useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import s from "./AddSources.module.css";
import { NavLink, useParams } from "react-router-dom";
import {
  Button,
  ErrorField,
  Htag,
  InputImageComponent,
} from "../../../../components";
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks";
import {
  Challenge,
  Feelling,
  Wellbeing,
} from "../../../../store/challenges/challenges.interface";
import { updateResource } from "../../../../store/challenges/challenges-thunk";

import { loadSingleWSResource } from "../../../../store/challenges/wsystem_resources.thunk";
import { EditorComponent } from "../../../../components/Editor/Editor.component";
import {
  FileProps,
  IAttach,
} from "../../../../store/system_resources/system_resource.interface";
import { InputFileComponent } from "../../../../components/InputFile/InputFile.component";
import { Multyselect } from "../../../../components/MultySelect/Multyselect.component";
import { useFormik } from "formik";
import { CWellbeing } from "models/Wellbeing.model";
import { validationCreateSchema } from "pages/AdminDashboard/validation";

export const EditWSSourcePage = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [currentAttachments, setAttachments] = useState<Partial<FileProps>[]>(
    []
  );
  const [attachmentsOld, setOldAttachments] = useState<Partial<FileProps>[]>(
    []
  );
  const source: Wellbeing = useAppSelector((state) => {
    return state.wellbeing.system_resources.filter(
      (resource: Wellbeing) => resource.id == id
    );
  })[0];

  const challenges: Challenge[] = useAppSelector(
    (state) => state.wellbeing.challenges.challenges
  );
  const feelings: Feelling[] = useAppSelector(
    (state) => state.wellbeing.feellings.feelings
  );
  const [challengeSelect, setChallengeSelect] = useState<string[] | number[]>(
    []
  );
  const [feelingSelect, setFeelingSelect] = useState<string[] | number[]>([]);

  const formik = useFormik({
    initialValues: new CWellbeing(),
    validationSchema: validationCreateSchema,
    onSubmit: (values) => {
      const updatedValue = formik.initialValues.formData(values);
      dispatch(
        updateResource({
          resource_id: Number(id),
          data: updatedValue,
        })
      )
        .unwrap()
        .then(() => {
          setOpen(true);
          setAttachments([]);
        });
    },
  });

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (!id) return;

    dispatch(loadSingleWSResource(Number(id)))
      .unwrap()
      .then((resp: any) => {
        setOldAttachments(resp.attachments);
        formik.setFieldValue("content", resp.content);
        formik.setFieldValue("need_journal", resp.need_journal);

        formik.setFieldValue("title", resp.title);
        formik.setFieldValue("unique_number", resp.unique_number);
        formik.setFieldValue("reading_time", resp.reading_time);
        formik.setFieldValue("image", resp.image);
        formik.setFieldValue("challenges", resp.challenges);

        formik.setFieldValue("feelings", resp.feelings);
      });
  }, []);

  const handleAtachments = (attachments: FileProps | IAttach) => {
    setAttachments((old) => [...old, attachments]);

    formik.setFieldValue("attachments", [...currentAttachments, attachments]);
  };

  const handleChalenges = (value: any) => {
    formik.setFieldValue("challenges", value);
  };
  const handleFeelings = (value: any) => {
    formik.setFieldValue("feelings", value);
  };

  return (
    <div className={s.formWrap}>
      <Htag tag="h3">Edit source: "{source.title}"</Htag>
      <form onSubmit={formik.handleSubmit}>
        <FormControl fullWidth className={s.title}>
          <input
            id="title"
            name="title"
            onBlur={formik.handleBlur}
            value={formik.values.title}
            type="text"
            onChange={formik.handleChange}
            placeholder="Title"
          />
          {formik.touched.title && formik.errors.title && (
            <ErrorField error={formik.errors.title} />
          )}
        </FormControl>
        <FormControl className={s.reading_time}>
          <input
            id="reading_time"
            name="reading_time"
            value={formik.values.reading_time}
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Reading time"
          />
          {formik.touched.reading_time && formik.errors.reading_time && (
            <ErrorField error={formik.errors.reading_time} />
          )}
        </FormControl>
        <FormControlLabel
          label="Need a journal"
          className={s.need_journal}
          control={
            <Checkbox
              name="need_journal"
              id="need_journal"
              checked={formik.values.need_journal}
              onChange={formik.handleChange}
            />
          }
        />
        <div className={s.formField}>
          <Htag tag="h4" className={s.headline}>
            Upload preview image
          </Htag>
          <InputImageComponent
            handleFiles={formik.setFieldValue}
            handleOnBlur={formik.setFieldValue}
            file={formik.values.image}
            id="image"
            name="image"
          />
        </div>

        <div className={s.formField}>
          <Htag tag="h4" className={s.headline}>
            Upload attachments
          </Htag>
          <InputFileComponent
            files={attachmentsOld}
            handleFiles={handleAtachments}
            id="editResource"
          />
        </div>
        <div className={s.wyswyg_editorWrap}>
          <EditorComponent
            value={formik.values.content}
            handleChange={(e) => formik.setFieldValue("content", e)}
          />
        </div>

        <div className={s.selects}>
          <Multyselect
            label="Challenge"
            selectData={formik.values.challenges}
            selectArray={challenges}
            handleData={handleChalenges}
          />
          <Multyselect
            label="Feeling"
            selectData={formik.values.feelings}
            selectArray={feelings}
            handleData={handleFeelings}
          />
        </div>
        <p className={s.previewLink}>
          <NavLink
            target="_blank"
            to={`/admin-dashboard/wellbeingSources/sresource/edit-page/${id}/preview`}
          >
            Preview
          </NavLink>
        </p>
        <Button apearance="secondary">Save</Button>
      </form>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          {source.title} is updated!
        </Alert>
      </Snackbar>
    </div>
  );
};
