import { CardProps } from "./Card.props";
import styles from "./Card.module.css";
import cn from "classnames";

export const Card = ({
  children,
  className,
  type,
  ...props
}: CardProps): JSX.Element => {
  return (
    <div
      className={cn(styles.Card, className, {
        [styles.resource_card]: type == "resource_card",
      })}
      {...props}
    >
      {children}
    </div>
  );
};
