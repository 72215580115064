import { useFormik } from "formik";
import * as yup from "yup";
import styles from "../OnBoarding.module.css";
import cn from "classnames";
import {
  setBoardingStep,
  setInterview,
} from "store/onBoarding/onboarding-reducer";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { Button, ErrorField, Htag, StepCircle } from "components";
import { useState } from "react";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { PasswordField } from "components/PasswordField/PasswordField.component";
export const validationSignupSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  name: yup
    .string()
    .min(2, "Name should be at least 2 characters length")
    .required("Name is required"),
  password: yup
    .string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!+@#$%^&*])(?=.{8,})/,
      "Must Contain minimum 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    )
    .min(8, "Password should be at least 8 characters length")

    .required("Password is required"),
  password_confirmation: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

export const RegData = (): JSX.Element => {
  const initInterview = useAppSelector(
    (state) => state.onRegistration.interview
  );

  const [showConfPass, setShowConfPass] = useState<boolean>(false);
  const activeStep = useAppSelector((state) => state.onRegistration.activeStep);

  const server_error = useAppSelector((state) => {
    if (
      typeof state.onRegistration.server_error !== "string" &&
      Array.isArray(state.onRegistration.server_error)
    ) {
      return state.onRegistration.server_error[0].email;
    } else return "";
  });

  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: initInterview,
    validationSchema: validationSignupSchema,
    onSubmit: () => {
      dispatch(setBoardingStep(activeStep));
    },
  });

  const updateInterview = (ev: React.ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(ev);
    dispatch(
      setInterview({
        ...initInterview,
        [`${ev.target.name}`]: ev.target.value,
      })
    );
  };

  return (
    <form
      id="form-3"
      onSubmit={formik.handleSubmit}
      className={cn(styles.form_group, styles.interview_form)}
    >
      <header className={styles.onboarding_header}>
        <StepCircle size="S" backgroundColor={"var(--main-dark)"}>
          <span className={styles.step_color}>{activeStep + 1}</span>
        </StepCircle>
        <Htag tag="h4">Create your account</Htag>
      </header>

      <div className={styles.formField}>
        <label className={styles.label}>First name</label>
        <input
          type="text"
          value={formik.values.name}
          onChange={updateInterview}
          onBlur={formik.handleBlur}
          name="name"
        />
        {formik.touched.name && formik.errors.name && (
          <ErrorField error={formik.errors.name} />
        )}
      </div>
      <div className={cn(styles.formField)}>
        <label className={styles.label}>Email address</label>
        <input
          type="text"
          onChange={updateInterview}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          name="email"
        />
        {formik.touched.email && formik.errors.email && (
          <ErrorField error={formik.errors.email} />
        )}

        {server_error && <ErrorField error={server_error} />}
      </div>

      <div className={cn(styles.formField)}>
        <label className={styles.label}>Create password</label>
        <PasswordField
          name="password"
          placeholder=""
          value={formik.values.password}
          handleChange={updateInterview}
          handleBlur={formik.handleBlur}
        />

        {formik.touched.password && formik.errors.password && (
          <ErrorField error={formik.errors.password} />
        )}
      </div>
      <div className={cn(styles.formField)}>
        <label className={styles.label}>Confirm password</label>
        <PasswordField
          name="password_confirmation"
          placeholder=""
          value={formik.values.password_confirmation}
          handleChange={updateInterview}
          handleBlur={formik.handleBlur}
        />

        <span
          className={styles.visibilityIcon}
          onClick={() => setShowConfPass(!showConfPass)}
        >
          {!showConfPass ? <VisibilityOffIcon /> : <VisibilityIcon />}
        </span>

        {formik.touched.password_confirmation &&
          formik.errors.password_confirmation && (
            <ErrorField error={formik.errors.password_confirmation} />
          )}
      </div>
      <div className={styles.buttonGroup}>
        <Button apearance="secondary" type="submit">
          Next
        </Button>
      </div>
    </form>
  );
};
