import { createAsyncThunk } from '@reduxjs/toolkit';
import { VERIFY_EMAIL } from 'store/config';
import { IInitState } from 'store/root-interface';
import { IUser } from 'store/userInfo/user-interface';

export const verifyEmailAction = createAsyncThunk<
  { user: IUser; token: string },
  string,
  { getState: IInitState; rejectValue: string }
>("@@verifyEmail", async function (verify_hash, { getState, rejectWithValue }) {
  const { userState } = getState() as IInitState;

  const header = {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + userState.token,
      responseType: "json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ verify_hash: verify_hash }),
  };
  const response = await fetch(VERIFY_EMAIL(), header);

  if (!response.ok) {
    return response
      .text()
      .then((result) => JSON.parse(result))
      .then((result) => {
        return rejectWithValue(result.errors);
      });
  } else {
    return (await response.json()) as { user: IUser; token: string };
  }
});
