import { ISummary } from "../../store/caseStudy/caseStudy.inderface";
import { summaryCSData } from "./caseStudySummary.component";
import s from "./caseStudySummary.module.css";

export const CaseStudySummaryUser = ({ data }: { data: ISummary[] }) => {
  return (
    <div className={s.summaryWrap}>
      {data.map((sItem: ISummary) => (
        <div key={sItem?.id} className={s.summaryItem}>
          {summaryCSData[sItem.id].icon}
          <p>
            <span className={s.summaryItemOption}>
              {summaryCSData[sItem.id].title}
            </span>{" "}
            {sItem.value}
          </p>
        </div>
      ))}
    </div>
  );
};
