import { useCallback, useEffect, useMemo, useState } from "react";
import {
  DataGrid,
  GridColDef,
  GridFilterModel,
  GridFilterOperator,
  GridSlots,
  GridSortModel,
  getGridDateOperators,
  getGridSingleSelectOperators,
  getGridStringOperators,
} from "@mui/x-data-grid";

import { Box, Typography } from "@mui/material";
import { DeleteForever } from "@mui/icons-material";
import s from "../SpecialSources.module.css";
import { useAppDispatch, useAppSelector } from "utils/hooks";

import {
  deleteMember,
  get_subscribers_list,
} from "store/waitingList/waitingList-thunk";
import dayjs from "dayjs";
import { IgetUsersApiRequest } from "store/waitingList/waitingList.interface";
import { DateFilterComponent } from "components/DateRange/DateRange.component";
import { setFilterModel } from "store/waitingList/waitingList-reducer";
import { CustomToolbar } from "components/CustomToolbarDataGrid/CustomToolbarDG.component";

export const SubscriptionList = () => {
  const dispatch = useAppDispatch();
  const membersList = useAppSelector((state) => state.waitingList.subscribers);

  const filterModel = useAppSelector(
    (state) => state.waitingList.subscribers.filterModel
  );

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 15,
  });

  const getUsers = (params: IgetUsersApiRequest) =>
    dispatch(get_subscribers_list(params));

  useEffect(() => {
    getUsers({
      page: paginationModel.page,
      limit: paginationModel.pageSize,
    });
  }, [dispatch, paginationModel.page, paginationModel.pageSize]);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "id",
        headerClassName: s.headerClassName,
        headerName: "Id",
        sortable: false,
        width: 70,
        disableColumnMenu: true,
        filterable: false,
      },
      {
        field: "name",
        headerClassName: s.headerClassName,
        headerName: "Name",
        sortable: true,
        width: 180,
        disableColumnMenu: true,
        operator: "contains",
        filterOperators: getGridStringOperators().filter(
          (operator: GridFilterOperator) => operator.value == "contains"
        ),
      },
      {
        field: "email",
        headerClassName: s.headerClassName,
        headerName: "Email",
        sortable: true,
        width: 200,
        disableColumnMenu: true,
        filterOperators: getGridStringOperators().filter(
          (operator: any) => operator.value == "contains"
        ),
      },
      {
        field: "created_at",
        headerClassName: s.headerClassName,
        headerName: "Date account created",
        sortable: true,
        width: 180,
        type: "date",
        disableColumnMenu: true,
        valueFormatter: (value) => {
          return dayjs(value).format("DD/MM/YYYY");
        },

        filterOperators: getGridDateOperators()
          .filter((operator: any) => operator.value == "is")
          .map((operator) => {
            return {
              ...operator,
              InputComponent: DateFilterComponent,
              InputComponentProps: { type: "date" },
            };
          }),
      },
      {
        field: "date_subscription",
        headerClassName: s.headerClassName,
        headerName: "Date of subscription",
        sortable: true,
        width: 170,

        disableColumnMenu: true,
        valueFormatter: (params: Date) => {
          if (params) return dayjs(params).format("DD/MM/YYYY");
        },
        filterOperators: getGridDateOperators()
          .filter((operator: any) => operator.value == "is")
          .map((operator) => {
            return {
              ...operator,
              InputComponent: DateFilterComponent,
              InputComponentProps: { type: "date" },
            };
          }),
      },
      {
        field: "membership_type",
        headerClassName: s.headerClassName,
        headerName: "Membership type",
        sortable: true,
        width: 150,
        disableColumnMenu: true,

        valueOptions: () => {
          return ["1 month", "6 months"];
        },
        type: "singleSelect",
        filterOperators: getGridSingleSelectOperators().filter(
          (operator: GridFilterOperator) => operator.value == "is"
        ),
      },
      {
        field: "discount_code",
        headerClassName: s.headerClassName,
        headerName: "Discount code",
        sortable: false,
        width: 130,
        disableColumnMenu: true,
        filterable: false,
      },
      {
        field: "next_renewal_date",
        headerClassName: s.headerClassName,
        headerName: "Next renewal date",
        sortable: false,
        width: 180,
        type: "date",
        filterable: true,
        disableColumnMenu: true,
        valueFormatter: (params: Date) => {
          if (params) return dayjs(params).format("DD/MM/YYYY");
        },
        filterOperators: getGridDateOperators()
          .filter((operator: any) => operator.value == "is")
          .map((operator) => {
            return {
              ...operator,
              InputComponent: DateFilterComponent,
              InputComponentProps: { type: "date" },
            };
          }),
      },
      {
        field: "status",
        headerClassName: s.headerClassName,
        headerName: "Status",
        sortable: true,
        width: 120,
        disableColumnMenu: true,
        valueOptions: () => {
          return ["Live", "Canceled"];
        },
        type: "singleSelect",
        filterOperators: getGridSingleSelectOperators().filter(
          (operator: GridFilterOperator) => operator.value == "is"
        ),
      },
      {
        field: "last_login",
        headerClassName: s.headerClassName,
        headerName: "Date of last sign in",
        sortable: false,
        width: 170,
        type: "date",
        disableColumnMenu: true,
        valueFormatter: (params: Date) => {
          if (params) return dayjs(params).format("DD/MM/YYYY");
        },
        filterOperators: getGridDateOperators()
          .filter((operator: any) => operator.value == "is")
          .map((operator) => {
            return {
              ...operator,
              InputComponent: DateFilterComponent,
              InputComponentProps: { type: "date" },
            };
          }),
      },
      {
        field: "inactive_14_days",
        headerClassName: s.headerClassName,
        headerName: "Inactive for > 14 days",
        sortable: false,
        width: 170,
        disableColumnMenu: true,
        filterable: true,
        valueOptions: () => {
          return ["Yes", "No"];
        },
        type: "singleSelect",
        filterOperators: getGridSingleSelectOperators().filter(
          (operator: GridFilterOperator) => operator.value == "is"
        ),
      },
      {
        field: "inactive_30_days",
        headerClassName: s.headerClassName,
        headerName: "Inactive for > 30 days",
        sortable: false,
        width: 170,
        disableColumnMenu: true,
        filterable: true,
        valueOptions: () => {
          return ["Yes", "No"];
        },
        type: "singleSelect",
        filterOperators: getGridSingleSelectOperators().filter(
          (operator: GridFilterOperator) => operator.value == "is"
        ),
      },
      {
        field: "milestone_progress",
        headerClassName: s.headerClassName,
        headerName: "Milestone progress",
        sortable: false,
        width: 150,
        disableColumnMenu: true,
        filterable: true,
        valueOptions: () => {
          return ["1", "2", "3", "4", "5"];
        },
        type: "singleSelect",
        filterOperators: getGridSingleSelectOperators().filter(
          (operator: GridFilterOperator) => operator.value == "is"
        ),
      },
      {
        field: "children",
        headerClassName: s.headerClassName,
        headerName: "Children",
        sortable: false,
        width: 130,
        disableColumnMenu: true,
        filterable: true,
        valueOptions: () => {
          return ["Yes", "No"];
        },
        type: "singleSelect",
        filterOperators: getGridSingleSelectOperators().filter(
          (operator: GridFilterOperator) => operator.value == "is"
        ),
      },
      {
        field: "action",
        headerName: ``,
        headerClassName: s.headerClassName,
        sortable: false,
        width: 120,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          return (
            <div className={s.actions}>
              {/* <CancelScheduleSend className={s.actionIcon} /> */}

              <DeleteForever
                onClick={() => dispatch(deleteMember(params.row.id))}
                className={s.actionIcon}
              />
            </div>
          );
        },
      },
    ],
    []
  );

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    getUsers({
      page: paginationModel.page,
      limit: paginationModel.pageSize,
      filter: filterModel.items,
    });
    dispatch(setFilterModel(filterModel));
  }, []);

  const onSortChange = (newSortModel: GridSortModel) => {
    getUsers({
      page: paginationModel.page,
      limit: paginationModel.pageSize,
      sort: newSortModel[0],
      filter: filterModel.items,
    });
  };

  return (
    <div className={s.box_wrap}>
      <Box sx={{ height: 865, width: "100%", paddingBottom: "30px" }}>
        <Typography variant="h3" component="h3">
          Members
        </Typography>
        <DataGrid
          columns={columns}
          rows={membersList.users}
          getRowId={(row) => row.id}
          filterMode="server"
          paginationMode="server"
          rowCount={membersList.totalPages}
          pageSizeOptions={[15, 30, 50, 70, 100]}
          pagination
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          className={s.tableWrap}
          filterModel={filterModel}
          hideFooterSelectedRowCount
          sortingMode="server"
          onFilterModelChange={onFilterChange}
          onSortModelChange={onSortChange}
          slots={{
            toolbar: CustomToolbar as GridSlots["toolbar"],
          }}
        />
      </Box>
    </div>
  );
};
