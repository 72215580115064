import { useState } from "react";
import { PasswordFieldProps } from "./PasswordField.props";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

import s from "./PasswordField.module.css";


export const PasswordField = ({
  placeholder,
  name,
  value,
  handleChange,
  handleBlur,
}: PasswordFieldProps): JSX.Element => {
  const [showPass, setShowPass] = useState<boolean>(false);
  return (
    <label className={s.label}>
      <input
        id={name}
        name={name}
        type={showPass ? "text" : "password"}
        value={value}
        placeholder={placeholder}
        onChange={(e) => handleChange(e)}
        onBlur={handleBlur}
      />
      <span className={s.visibilityIcon} onClick={() => setShowPass(!showPass)}>
        {!showPass ? <VisibilityOffIcon /> : <VisibilityIcon />}
      </span>
    </label>
  );
};
