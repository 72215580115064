import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { deleteCS, get_all_cs,  get_user_all_cs } from "./caseStudy_thunk";
import { CaseStudiesSlice } from "./caseStudy.inderface";

const initCS: CaseStudiesSlice = {
  caseStudiesArray: [],
  caseStudies_db: [],
  error: "",
};

const caseStudiesState = createSlice({
  name: "@@caseStudies",
  initialState: initCS,
  reducers: {
    addCSId: (
      state: CaseStudiesSlice,
      action: PayloadAction<string | string[]>
    ) => {
      if (typeof action.payload == "string") {
        state.caseStudies_db.push(action.payload);
      } else {
        const toRemove = new Set(action.payload);

        state.caseStudies_db =
          state.caseStudies_db.filter((x) => !toRemove.has(x)) || [];
        state.caseStudies_db = state.caseStudies_db.concat(action.payload);
      }
    },
	
	
    removeCSId: (
      state: CaseStudiesSlice,
      action: PayloadAction<string | string[]>
    ) => {
      if (typeof action.payload == "string") {
        state.caseStudies_db = state.caseStudies_db.filter(
          (item) => item !== action.payload
        );
      } else {
        const toRemove = new Set(action.payload);

        state.caseStudies_db =
          state.caseStudies_db.filter((x) => !toRemove.has(x)) || [];
      }
    },
    setNewCS: (state, action) => {
      return action.payload;
    },
    removeCS: (state, action) => {
      state.caseStudiesArray = state.caseStudiesArray.filter(
        (item) => item.id !== action.payload
      );
    },
    reset: () => initCS,
    resetCSIds: (state) => {
      state.caseStudies_db = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_all_cs.fulfilled, (state, action) => {
        state.caseStudiesArray = action.payload;
      })
      .addCase(deleteCS.fulfilled, (state, action) => {
        state.caseStudiesArray = state.caseStudiesArray.filter(
          (item) => item.id !== action.payload.id
        );
      })
      .addCase(get_user_all_cs.fulfilled, (state, action) => {
        state.caseStudiesArray = action.payload;
      });
  },
});

export const { reset, setNewCS, addCSId, resetCSIds, removeCSId } =
  caseStudiesState.actions;

export default caseStudiesState.reducer;
