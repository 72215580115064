import { Button, Htag } from "components";

import s from "./ContentTemplates.module.css";
import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { Page } from "store/simplePages/simplePages-inderfaces";

import { Delete, Edit } from "@mui/icons-material";
import { useEffect, useState } from "react";
import {
  deleteSimplePage,
  loadSimplePages,
} from "store/simplePages/simplePages-thunk";
import dayjs from "dayjs";
import { NavLink } from "react-router-dom";

export const AllPages = () => {
  const userToken = useAppSelector((state) => state.userState.token);
  const { pages } = useAppSelector((state) => state.pages);
  const dispatch = useAppDispatch();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (!pages) dispatch(loadSimplePages(userToken));
  }, [userToken]);

  const deletePage = (id: number | undefined) => {
    if (!id) return;
    dispatch(deleteSimplePage(id))
      .unwrap()
      .then(() => {
        return;
      });
  };

  const handleClose = () => {
    setOpenDeleteDialog(false);
    setDeleteId(undefined);
  };

  const confirmDelete = () => {
    if (!deleteId) return;
    deletePage(deleteId);
    setOpenDeleteDialog(false);
    setDeleteId(undefined);
  };

  return (
    <>
      <Htag tag="h3" className={s.title}>
        List of pages
      </Htag>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow className={s.tableHead}>
              <TableCell>Title</TableCell>
              <TableCell>Create Date</TableCell>
              <TableCell>Update Date</TableCell>
              <TableCell>Link</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pages.length &&
              pages.map((row: Page) => (
                <TableRow
                  key={row.title}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.title}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {dayjs(row.created_at).format("MMMM DD, YYYY")}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {dayjs(row.updated_at).format("MMMM DD, YYYY")}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <NavLink to={`/${row.slug}`}>{row.slug}</NavLink>
                  </TableCell>
                  <TableCell component="th" scope="row" sx={{ width: "100px" }}>
                    <div className={s.cellButtons}>
                      <NavLink
                        to={`/admin-dashboard/all-pages/edit-page/${row.id}`}
                      >
                        <IconButton aria-label="edit" color="info">
                          <Edit />
                        </IconButton>
                      </NavLink>
                      <IconButton
                        aria-label="delete"
                        color="error"
                        onClick={() => {
                          setOpenDeleteDialog(true);
                          setDeleteId(row.id);
                        }}
                      >
                        <Delete className={s.deleteIcon} />
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={openDeleteDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={s.deleteConfirmDialog}
      >
        <DialogContent className={s.deleteConfirmText}>
          Please, confirm deleting
        </DialogContent>
        <DialogActions className={s.deleteConfirmBtn}>
          <Button apearance="secondaryDark" onClick={handleClose}>
            NO
          </Button>
          <Button apearance="secondaryDark" onClick={confirmDelete}>
            AGREE
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
