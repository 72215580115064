import { createAsyncThunk } from "@reduxjs/toolkit";
import { IInitState } from "../root-interface";
import { ICaseStudies } from "./caseStudy.inderface";
import {
  ADD_CS_KEYS,
  CREATE_CS,
  DELETE_CS,
  GET_ALL_CS,
  GET_ALL_SP_CS,
  GET_CS,
  GET_USER_SP_CS,
  UPDATE_CS,
} from "../config";
import { setToken } from "../userInfo/userInfo-reducer";

export const add_cs = createAsyncThunk<
  { success: string },
  FormData,
  { getState: IInitState; rejectValue: string }
>(
  "@@caseStudies/adding",
  async function (data, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + userState.token,
      },
      body: data,
    };

    const response = await fetch(CREATE_CS(), header);
    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
        if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          return rejectWithValue(result.errors);
        });
    } else {
      return (await response.json()) as { success: string };
    }
  }
);

export const edit_cs = createAsyncThunk<
  { success: string },
  { data: FormData; id: string },
  { getState: IInitState; rejectValue: string }
>(
  "@@caseStudies/edit",
  async function ({ data, id }, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + userState.token,
      },
      body: data,
    };

    const response = await fetch(UPDATE_CS(id), header);
    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
        if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          return rejectWithValue(result.errors);
        });
    } else {
      return (await response.json()) as { success: string };
    }
  }
);

export const get_all_cs = createAsyncThunk<
  ICaseStudies[],
  undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@caseStudies/getAllCS",
  async function (_, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + userState.token,
        responseType: "json",
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(GET_ALL_CS(), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
        if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as ICaseStudies[];
    }
  }
);
export const get_user_all_cs = createAsyncThunk<
  ICaseStudies[],
  undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@caseStudies/getUserAllCS",
  async function (_, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + userState.token,
        responseType: "json",
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(GET_USER_SP_CS(), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
        if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as ICaseStudies[];
    }
  }
);

export const get_all_special_cs = createAsyncThunk<
  ICaseStudies[],
  undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@caseStudies/getAllCS",
  async function (_, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + userState.token,
        responseType: "json",
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(GET_ALL_SP_CS(), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
        if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as ICaseStudies[];
    }
  }
);

export const get_cs = createAsyncThunk<
  ICaseStudies,
  string,
  { getState: IInitState; rejectValue: string }
>(
  "@@caseStudies/getCS",
  async function (id, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + userState.token,
        responseType: "json",
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(GET_CS(id), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {

        localStorage.removeItem("userToken");
        if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as ICaseStudies;
    }
  }
);

export const deleteCS = createAsyncThunk<
  { id: number },
  number,
  { getState: IInitState; rejectValue: string }
>(
  "@@caseStudies/deleteCS",
  async function (cs_id, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + userState.token,
        responseType: "json",
      },
    };
    const response = await fetch(DELETE_CS(cs_id), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
		if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          if (result.errors) {
            return rejectWithValue(result.errors[0]);
          }
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as { id: number };
    }
  }
);

export const add_case_studies = createAsyncThunk<
  { success: string },
  string[],
  { getState: IInitState; rejectValue: string }
>(
  "@@caseStudies/addingCs",
  async function (unique_numbers, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "POST",
      headers: {
        Accept: "application/json",
        responseType: "json",
        Authorization: "Bearer " + userState.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ unique_numbers: unique_numbers }),
    };
    const response = await fetch(ADD_CS_KEYS(), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
		if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as { success: string };
    }
  }
);
