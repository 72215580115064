import { createSlice } from "@reduxjs/toolkit";
import { resetToDefault } from "../login/login-reducer";
import { loadSingleSpace, loadSpaces } from "./spaces-actions";
import { IState } from "./spaces-interface";

const initialState: IState = {
  spaces: [],

  loading: false,
  error: "",
};

const onSpaces = createSlice({
  name: "Spaces",
  initialState,
  reducers: {
    setChildrenResources: (state, action) => {
      state.spaces = state.spaces.map((space) => {
        if (space.id == 2 && space.name == "Children") {
          space.space_resources = action.payload;
        }

        return space;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadSpaces.fulfilled, (state, action) => {
        state.spaces = action.payload;
      })
      .addCase(resetToDefault, () => {
        return initialState;
      })
      .addCase(loadSingleSpace.fulfilled, (state, action) => {
        if (state.spaces.find((item) => item.id == action.payload.id)) {
          state.spaces = state.spaces.map((space) => {
            if (space.id == action.payload.id) return action.payload;
            return space;
          });
        } else {
          state.spaces.push(action.payload);
        }
      })
      .addMatcher(
        (action) => action.type.endsWith("/pending"),
        (state) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state, action) => {
          state.loading = false;

          if (action.payload) {
            state.error = action.payload;
          } else if (action.error) {
            state.error = `Server error:  ${action.error}`;
          }
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/fulfilled"),
        (state) => {
          state.loading = false;
          state.error = "";
        }
      );
  },
});
export const {
 setChildrenResources
} = onSpaces.actions;
export default onSpaces.reducer;
