import { Box, Card, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import {  Htag, Notification } from "components";
import { ChangePassAccount } from "./ChangePassAccount/ChangePassAccount.component";

import s from "./User.module.css";
import { PersonalInfoForm } from "./PersonalInfoForm/PersonalInfoForm.component";
import { FinancialPicture } from "./FinancialPicture/FinancialPicture.component";
import { useAppDispatch, useAppSelector } from "utils/hooks";

import { SubscriptionSettings } from "./SubscriptionSettings/SubscriptionSettings.component";
import {
  setBoardingStep,
  setRegModalStatus,
} from "store/onBoarding/onboarding-reducer";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export const User = () => {
  const [value, setValue] = useState(0);
  const dispatch = useAppDispatch();
  const milestoneMemory = useAppSelector((state) =>
    state.userState.user.memory.filter(
      (item: any) => item.name == `Notification_Profile`
    )
  );
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const subscriptionStatus = useAppSelector(
    (state) => state.accountState.subscription.status
  );

  return (
    <div className={s.accountContainer}>
      {subscriptionStatus !== "active" ? (
        <Htag tag="h3" className={s.pageTitle}>
          You’re a step closer to creating your personalised space.
        </Htag>
      ) : (
        <Htag tag="h3" className={s.pageTitle}>
          Your information
        </Htag>
      )}
      {subscriptionStatus !== "active" &&
        milestoneMemory[0]?.value !== "true" && (
          <Notification name={`Profile`} className={s.notificationMsg}>
            You've set up an account with SeparateSpace, but you haven't
            purchased your Membership yet. You can do that{" "}
            <span
              onClick={() => {
                dispatch(setRegModalStatus(true));
                dispatch(setBoardingStep(3));
              }}
              className="hover-underline-animation_back"
            >
              here
            </span>
            .
            <br />
            Once you've purchased your Membership, you'll get access to the
            resources and tools you need to navigate your divorce.
          </Notification>
        )}

      <Card className={s.formCard}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons
            onChange={handleChange}
            aria-label="basic tabs example"
            className={s.tabsLabes}
            sx={{
              "& .MuiTabs-indicator": { backgroundColor: "#4EB072" },

              "& .Mui-selected": { color: "#4EB072!important" },
            }}
          >
            <Tab className={s.tabLabel} label="You" />
            <Tab className={s.tabLabel} label="Security" />
            <Tab
              className={s.tabLabel}
              label="Your subscription information"
            />{" "}
            {subscriptionStatus == "active" && (
              <Tab className={s.tabLabel} label="Your financial picture" />
            )}
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <PersonalInfoForm />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <ChangePassAccount />
        </TabPanel>

        <TabPanel value={value} index={2}>
          <SubscriptionSettings />
        </TabPanel>
        {subscriptionStatus == "active" && (
          <TabPanel value={value} index={3}>
            {subscriptionStatus ? <FinancialPicture /> : <p>No data</p>}
          </TabPanel>
        )}
      </Card>
    </div>
  );
};
