import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ADD_SYSTEM_RESOURCES,
  GET_RESENT_RESOURCES,
  GET_SINGLE_RESOURCE,
  GET_SPACE_RESOURCES,
  GET_SYSTEM_RESOURCES,
  UPDATE_SYSTEM_RESOURCE,
} from "../config";
import { IInitState } from "../root-interface";
import { ISystemResource } from "./system_resource.interface";
import { setToken } from "../userInfo/userInfo-reducer";

export const add_system_resources = createAsyncThunk<
  { success: string },
  string[],
  { getState: IInitState; rejectValue: string }
>(
  "@@systemresources/adding",
  async function (unique_numbers, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "POST",
      headers: {
        Accept: "application/json",
        responseType: "json",
        Authorization: "Bearer " + userState.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ unique_numbers: unique_numbers }),
    };
    const response = await fetch(ADD_SYSTEM_RESOURCES(), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
        if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as { success: string };
    }
  }
);

export const get_system_resources = createAsyncThunk<
  ISystemResource[],
  undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@systemresources/getting",
  async function (_, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "GET",
      headers: {
        Accept: "application/json",
        responseType: "json",
        Authorization: "Bearer " + userState.token,
      },
    };
    const response = await fetch(GET_SYSTEM_RESOURCES(), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
        if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as ISystemResource[];
    }
  }
);
export const get_space_resources = createAsyncThunk<
  ISystemResource[],
  undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@systemresources/gettingSpace",
  async function (_, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "GET",
      headers: {
        Accept: "application/json",
        responseType: "json",
        Authorization: "Bearer " + userState.token,
      },
    };
    const response = await fetch(GET_SPACE_RESOURCES(), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
        if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as ISystemResource[];
    }
  }
);
export const get_recent_resources = createAsyncThunk<
  ISystemResource[],
  undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@systemresources/gettingRecent",
  async function (_, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "GET",
      headers: {
        Accept: "application/json",
        responseType: "json",
        Authorization: "Bearer " + userState.token,
      },
    };
    const response = await fetch(GET_RESENT_RESOURCES(), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
        if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as ISystemResource[];
    }
  }
);

export const get_single_sresource = createAsyncThunk<
  ISystemResource,
  string,
  { getState: IInitState; rejectValue: string }
>(
  "@@systemresources/gettingSingleResource",
  async function (id, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "GET",
      headers: {
        Accept: "application/json",
        responseType: "json",
        Authorization: "Bearer " + userState.token,
      },
    };
    const response = await fetch(GET_SINGLE_RESOURCE(Number(id)), header);
   
    if (!response.ok) {
    
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
        if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          if (result) return rejectWithValue(result.error);
          else return rejectWithValue("Server error");
        });
    } else {

      return (await response.json()) as ISystemResource;
    }
  }
);

export const updateSystemResource = createAsyncThunk<
  { success: string; resource: ISystemResource },
  {
    sr_id: string;
    sr_data: FormData;
  },
  { getState: IInitState; rejectValue: string }
>(
  "@@systemresources/updateResource",
  async function ({ sr_id, sr_data }, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + userState.token,
      },
      body: sr_data,
    };

    const response = await fetch(UPDATE_SYSTEM_RESOURCE(sr_id), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
        if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          return rejectWithValue(result);
        });
    } else {
      return (await response.json()) as {
        success: string;
        resource: ISystemResource;
      };
    }
  }
);
