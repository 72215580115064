import { createAsyncThunk } from "@reduxjs/toolkit";
import { IInitState } from "../root-interface";
import { GET_ALL_MILESTONES, GET_MILESTONE } from "../config";
import { Milestone } from "./milestones-interface";
import { ITicket } from "../tickets/tickets-interface";
import { setToken } from "../userInfo/userInfo-reducer";

export const loadMilestones = createAsyncThunk<
  Milestone[],
  undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@spaces/milestones",
  async function (_, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;
    //   const dispatch = useAppDispatch();
    const header = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + userState.token,
        responseType: "json",
      },
    };

    const response = await fetch(GET_ALL_MILESTONES(), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
        if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as Milestone[];
    }
  }
);

export const loadMilestone = createAsyncThunk<
  ITicket[],
  number,
  { getState: IInitState; rejectValue: string }
>("@@spaces/milestone", async function (id, { getState, rejectWithValue, dispatch }) {
  const { userState } = getState() as IInitState;
  const header = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + userState.token,
      responseType: "json",
    },
  };
  const response = await fetch(GET_MILESTONE(id), header);

  if (!response.ok) {
    if ([401, 403].includes(response.status)) {
      localStorage.removeItem("userToken");
      if (userState.token) dispatch(setToken(null));
    }
    return response
      .text()
      .then((result) => JSON.parse(result))
      .then((result) => {
        return rejectWithValue(result.message);
      });
  } else {
    return (await response.json()) as ITicket[];
  }
});
