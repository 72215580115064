import { Chip, List, ListItem } from "@mui/material";
import {
  Agenda,
  Card,
  Htag,
  Legal,
  Letter,
  Mail,
  Script,
  Spreadsheet,
} from "components";
import { useAppSelector } from "utils/hooks";
import s from "./Templates.module.css";
import { templatePreview } from "./Tepmlates.images";
import { downloadAttach } from "controlls/tickets-controll";
import cn from "classnames";
import { useState } from "react";

const templateImage = [
  <Agenda />,
  <Legal />,
  <Letter />,
  <Mail />,
  <Script />,
  <Spreadsheet />,
];

export const TemplatesComponent = () => {
  const templates = useAppSelector((state) => state.tickets.templates);
  const [showIndex, setShowIndex] = useState(3);
  const btnText = ["See all templates", "See less templates"];
  const [btnState, setBtnState] = useState(0);

  const buttonStyle = () => {
    if (btnState == 0) {
      setBtnState(1);
      setShowIndex(templates.length);
    } else {
      setBtnState(0);
      setShowIndex(3);
    }
  };

  return (
    <div className={s.templates_wrap}>
      <div className={s.templates_header}>
        <Htag tag="h2standard">Templates</Htag>
        <span
          className={cn(
            {
              [s.showBtn]: templates.length > showIndex,
              [s.hideBtn]: templates.length < showIndex,
            },
            "hover-underline-animation_back"
          )}
          onClick={buttonStyle}
        >
          {btnText[btnState]}
        </span>
      </div>
      <p>
        Quick links to emails, letters, documents and spreadsheets drafted by
        our legal experts.
      </p>
      <div className={s.mobile_scroll_container}>
        <List className={s.templates_list}>
          {templates.length > 0 &&
            templates.slice(0, showIndex).map((template) => (
              <ListItem
                key={`${template.id}${Math.random()}`}
                className={s.listItem}
              >
                <Card
                  key={template.id}
                  className={s.templateCard}
                  onClick={() => {
                    if (template.special_ticket_id) {
                      downloadAttach(
                        template.id,
                        +template.special_ticket_id,
                        template.name
                      );
                    }
                  }}
                >
                  <div
                    className={cn(s.template_preview, {
                      [s.agenda]: template.type === 0,
                      [s.legal]: template.type == 1,
                      [s.letter]: template.type == 2,
                      [s.mail]: template.type == 3,
                      [s.script]: template.type == 4,
                      [s.spreadsheet]: template.type == 5,
                    })}
                  >
                    {template.type || template.type === 0 ? (
                      templateImage[template.type]
                    ) : (
                      <img src={templatePreview} alt="" />
                    )}
                  </div>
                  <div className={s.template_meta}>
                    <p> {template.name}</p>
                    <Chip label="Template" variant="outlined" size="medium" />
                  </div>
                </Card>
              </ListItem>
            ))}
        </List>
      </div>
    </div>
  );
};
