import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ADD_WELLBEING_SYSTEM_RESOURCE,
  GET_SINGLE_WELLBEING_RESOURCE,
  GET_SYSTEM_WELLBEING_RESOURCES,
} from "../config";
import { IInitState } from "../root-interface";
import { Wellbeing } from "./challenges.interface";
import { useAppDispatch } from "../../utils/hooks";
import { setToken } from "../userInfo/userInfo-reducer";

export const loadSystemWResources = createAsyncThunk<
  Wellbeing[],
  undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@wellbeing/allWSystemResources",
  async function (_, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + userState.token,
        responseType: "json",
      },
    };

    const response = await fetch(GET_SYSTEM_WELLBEING_RESOURCES(), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
        if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          if (result.errors) {
            return rejectWithValue(result.errors[0]);
          }
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as Wellbeing[];
    }
  }
);

export const loadSingleWSResource = createAsyncThunk<
  Wellbeing,
  number,
  { getState: IInitState; rejectValue: string }
>(
  "@@wellbeing/loadSingleWSReducer",
  async function (id, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + userState.token,
        responseType: "json",
      },
    };

    const response = await fetch(GET_SINGLE_WELLBEING_RESOURCE(id), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
        if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          if (result.errors) {
            return rejectWithValue(result.errors[0]);
          }
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as Wellbeing;
    }
  }
);

export const add_wsystem_resources = createAsyncThunk<
  { success: string },
  string[],
  { getState: IInitState; rejectValue: string }
>(
  "@@wellbeing/systemresourcesadding",
  async function (unique_numbers, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "POST",
      headers: {
        Accept: "application/json",
        responseType: "json",
        Authorization: "Bearer " + userState.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ unique_numbers: unique_numbers }),
    };
    const response = await fetch(ADD_WELLBEING_SYSTEM_RESOURCE(), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
        if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as { success: string };
    }
  }
);
