import s from "./GeneralOnboarding.module.css";

import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { QuestionnaireProps } from "pages/Dashboard/Spaces/Questionnaire.props";
import {
  addResourceId,
  removeResourceId,
} from "store/system_resources/system_resource-reducer";
import { Accordion, Button, ErrorField, Htag } from "components";
import { useFormik } from "formik";

import * as yup from "yup";

const desc = `
    <p>
    If you or your ex own any other properties (and by that we mean any properties that are not your family home), it will need to be taken into account when looking at a financial settlement.  If this is relevant to you, we’ll give you some guidance about things you will need to think about.  
    </p>`;

const validationForm8 = yup.object({
  other_properties: yup
    .string()
    .nullable()
    .required("Please select an option."),
});

export const FormGB8 = ({
  handleBack,
  setData,
  handleNextStep,
}: QuestionnaireProps): JSX.Element => {
  const { generalOnboarding, activeStep } = useAppSelector(
    (state) => state.generalOnBoardingState
  );

  const dispatch = useAppDispatch();

  const updateInterview = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = ev.target;

      if (value == "yes") {
        dispatch(addResourceId(["FR19"]));
      } else if (value == "no") {
        dispatch(removeResourceId(["FR19"]));
      }
      formik.handleChange(ev);
      setData(name, value);
    },
    [dispatch, generalOnboarding]
  );
  const formik = useFormik({
    initialValues: generalOnboarding,
    validationSchema: validationForm8,
    onSubmit: () => {
      handleNextStep();
    },
  });

  return (
    <form id="form-8" onSubmit={formik.handleSubmit} className={s.form}>
      <span className={s.stepMark}>{`${activeStep} `}</span>
      <>
        <Htag tag="h3">
          Do you or your ex own any other properties? It might be an investment
          property or a holiday home, and it may be a part-share in it.
        </Htag>
        <div className={s.form_oneline}>
          <div className={s.formField}>
            <RadioGroup row name="other_properties" className={s.radioboxes}>
              <FormControlLabel
                value="yes"
                control={<Radio color="default" onChange={updateInterview} />}
                label="Yes"
                checked={formik.values.other_properties == "yes"}
              />
              <FormControlLabel
                value="no"
                control={<Radio color="default" onChange={updateInterview} />}
                label="No"
                checked={formik.values.other_properties == "no"}
              />
            </RadioGroup>
            {formik.touched.other_properties &&
              formik.errors.other_properties && (
                <ErrorField error={formik.errors.other_properties} />
              )}
          </div>
        </div>
      </>

      <Accordion
        className={s.desc}
        accordionData={[
          {
            title: `Why are we asking?`,
            content: desc,
          },
        ]}
      />

      <div className={s.groupButton}>
        <Button
          onClick={() => handleBack()}
          apearance="default"
          className={s.goBack}
          type="button"
        >
          Previous
        </Button>
        <Button type="submit" apearance="secondary">
          Next
        </Button>
      </div>
    </form>
  );
};
