import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ADD_EXTRA_RESOURCES,
  DELETE_EXTRA_RESOURCES,
  EDIT_EXTRA_RESOURCES,
  GET_ALL_EXTRA_RESOURCES,
  GET_EXTRA_RESOURCE,
} from "../config";
import { IInitState } from "../root-interface";
import { IExtraResource } from "./extra_resource.interface";
import { setToken } from "../userInfo/userInfo-reducer";

export const get_all_extra_resources = createAsyncThunk<
  IExtraResource[],
  undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@extraresources/getting",
  async function (_, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "GET",
      headers: {
        Accept: "application/json",
        responseType: "json",
        Authorization: "Bearer " + userState.token,
      },
    };
    const response = await fetch(GET_ALL_EXTRA_RESOURCES(), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
        if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as IExtraResource[];
    }
  }
);

export const get_single_eresource = createAsyncThunk<
  IExtraResource,
  string,
  { getState: IInitState; rejectValue: string }
>(
  "@@extraresources/gettingSingleResource",
  async function (id, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "GET",
      headers: {
        Accept: "application/json",
        responseType: "json",
        Authorization: "Bearer " + userState.token,
      },
    };
    const response = await fetch(GET_EXTRA_RESOURCE(Number(id)), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
        if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          if (result) return rejectWithValue(result.error);
          else return rejectWithValue("Server error");
        });
    } else {
      return (await response.json()) as IExtraResource;
    }
  }
);

export const updateExtraResource = createAsyncThunk<
  { success: string; resource: IExtraResource },
  {
    er_id: string;
    er_data: FormData;
  },
  { getState: IInitState; rejectValue: string }
>(
  "@@extraresources/updateResource",
  async function ({ er_id, er_data }, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;
 
    const header = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + userState.token,
      },
      body: er_data,
    };

    const response = await fetch(EDIT_EXTRA_RESOURCES(er_id), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
        if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as {
        success: string;
        resource: IExtraResource;
      };
    }
  }
);

export const deleteEResource = createAsyncThunk<
  number,
  number,
  { getState: IInitState; rejectValue: string }
>(
  "@@extraresources/deleteResource",
  async function (resource_id, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + userState.token,
        responseType: "json",
      },
    };
    const response = await fetch(DELETE_EXTRA_RESOURCES(resource_id), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
		if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          if (result.errors) {
            return rejectWithValue(result.errors[0]);
          }
          return rejectWithValue(result.message);
        });
    } else {
      return resource_id;
    }
  }
);

export const addExtraResource = createAsyncThunk<
  { success: string; resource: IExtraResource },
  FormData,
  { getState: IInitState; rejectValue: string }
>(
  "@@extraresources/addResource",
  async function (data, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + userState.token,
      },
      body: data,
    };
    const response = await fetch(ADD_EXTRA_RESOURCES(), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
		if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          if (result.errors) {
            return rejectWithValue(result.errors[0]);
          }
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as {
        success: string;
        resource: IExtraResource;
      };
    }
  }
);
