import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { resetToDefault } from "../login/login-reducer";
import { IAccountSlice } from "./user-interface";

const initialState: IAccountSlice = {
  products: [],
  subscription: {
    status: "",
    expired_date: "",
    sub_status: "",
  },
};

const accountSlice = createSlice({
  name: "@@accountEntity",
  initialState: initialState,
  reducers: {
    setSubscriptionData: (
      state,
      action: PayloadAction<{
        status: string;
        expired_date: string;
        sub_status: string;
      }>
    ) => {
      state.subscription.status = action.payload.status;
      state.subscription.expired_date = action.payload.expired_date;
      state.subscription.sub_status = action.payload.sub_status;
    },
    setProducts: (
      state: IAccountSlice,
      action: PayloadAction<{ [key: string]: string }>
    ) => {
      if (!action.payload) return;
      for (const [key, value] of Object.entries(action.payload)) {
        state.products.push({
          id: key,
          label: value,
        });
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetToDefault, () => {
      return { ...initialState, token: localStorage.getItem("userToken") };
    });
  },
});

export const { setSubscriptionData, setProducts } = accountSlice.actions;

export default accountSlice.reducer;
