import { useEffect, useState } from "react";

import { Button, Card, ChildrenIcon, Htag, WellbeingIcon } from "components";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import s from "./Dashboard.module.css";
import cn from "classnames";
import { useNavigate } from "react-router-dom";

import { Milestone } from "store/milestones/milestones-interface";
import { loadMilestones } from "store/milestones/milestones-thunk";
import { get_recent_resources } from "store/system_resources/system_resource-thunk";
import { ISystemResource } from "store/system_resources/system_resource.interface";
import { ResourcesListComponent } from "./Spaces/SResources/ResourcesList.component";
import { ITicket } from "store/tickets/tickets-interface";
import cloneDeep from "lodash.clonedeep";
import TimeLineItem from "components/TimelineItem/TimeLineItem.component";

export const space_milestones = [
  "Make interim arrangements",
  "Start the legal divorce process",
  "Untangle the finances",
  "Formalise your agreements",
  "When you're divorced",
];

export const Dashboard = (): JSX.Element => {
  const navigate = useNavigate();
  const [milestones, setMilestones] = useState<Milestone[]>([]);
  const [unFinished, setUnfinished] = useState<Milestone>();
  const [unFinishedTickets, setUnfinishedTickets] = useState<ITicket[]>([]);

  const [recent_resources, setRecent_resources] = useState<ISystemResource[]>(
    []
  );

  const has_children = useAppSelector(
    (state) =>
      state.userState.user.questionnaires.generalQuestionnary?.has_children
  );

  const name = useAppSelector((state) => state.userState.user.name);

  const dispatch = useAppDispatch();

  const findUnfinishedMilestone = (arr: Milestone[]) => {
    const value = Object.assign(
      {},
      arr.find((item) => item.complete_percent !== 100)
    );
    const wrap_array = cloneDeep(value.tickets).splice(0, 3);
    setUnfinishedTickets(wrap_array);

    return value;
  };

  useEffect(() => {
    dispatch(loadMilestones())
      .unwrap()
      .then((res: Milestone[]) => {
        setMilestones(res);
        setUnfinished(findUnfinishedMilestone(res));
      });
    dispatch(get_recent_resources())
      .unwrap()
      .then((res: ISystemResource[]) => {
        setRecent_resources(res);
      });
  }, []);

  return (
    <>
      <header className={s.dashboardHeader}>
        <Htag tag="h1">Hi {name}</Htag>
      </header>
      <section className={s.milestones}>
        {milestones.map((milestone, index) => (
          <TimeLineItem
            index={index + 1}
            id={milestone.id.toString()}
            title={milestone.title}
            key={index}
            completed_percent={milestone.complete_percent}
          />
        ))}
      </section>
      <section className={s.divorce_pathway_wrap}>
        <Htag tag="h2standard">Your divorce pathway</Htag>
        <div className={s.divorce_pathway_subtitle}>
          <Htag tag="h3">Personalised to you</Htag>
          <Button
            apearance="default"
            onClick={() => navigate("/dashboard/pathway")}
          >
            See whole pathway
          </Button>
        </div>
        <Card
          className={s.userPathStatus}
          onClick={() => navigate(`/dashboard/milestone/${unFinished?.id}`)}
        >
          <div className={s.userPathStatus_header}>
            <Htag tag="h2standard">{unFinished?.title}</Htag>
            <p>Stage {unFinished?.id} of 5</p>
          </div>
          <div className={s.unfinishedTickets}>
            {unFinishedTickets.map((ticket) => (
              <p key={ticket.id}>{ticket.title}</p>
            ))}
          </div>
        </Card>
      </section>
      <section className={s.recent_guides}>
        <Htag tag="h2standard">Welcome to SeparateSpace</Htag>

        <ResourcesListComponent
          showTgas={false}
          show_in_row={3}
          resources={recent_resources}
        />
      </section>
      <section className={cn(s.spaces_wrap)}>
        <Htag tag="h2standard">
          {has_children == "yes" && "Your Children and"} Your Wellbeing
        </Htag>
        <Htag tag="h3">Toolkits tailored to you</Htag>
        <div className={s.spaces_links}>
          {has_children == "yes" && (
            <Card
              onClick={() => navigate("/dashboard/space/2")}
              className={cn("blue_bg", s.space_card)}
            >
              <ChildrenIcon />
              <Htag tag="h2standard">Children</Htag>
              <p>
                Legal guidance, templates and scripts created by experts in
                family law, relationships and child psychology.
              </p>
            </Card>
          )}
          <Card
            className={cn("wellbeing_bg", s.space_card)}
            onClick={() => navigate("/dashboard/my-wellbeing")}
          >
            <WellbeingIcon />
            <Htag tag="h2standard">Wellbeing</Htag>
            <p>
              Explore how you’re feeling or challenges you’re facing and get
              some advice from experts in the wellbeing field.
            </p>
          </Card>
        </div>
        <section className={cn(s.spaces_wrap, s.experts)}>
          <Htag tag="h2standard">Book your expert</Htag>
          <p
            style={{
              fontSize: "30px",
              fontWeight: "500",
              marginBottom: "20px",
            }}
          >
            Book a 60-minute session with a divorce expert at a time that suits
            you. Get all your questions answered, one-to-one.
          </p>
          <Card className={s.coaching_preview}>
            <Htag tag="h3_middle">Divorce expert session</Htag>
            <p>
              SeparateSpace members can book this discounted divorce expert
              session.
            </p>
            <Htag tag="h3_middle">£195 per one-off session</Htag>
          </Card>
          <Button
            onClick={() => navigate("divorce-expert")}
            apearance="dark"
            size="M"
          >
            Learn more
          </Button>
        </section>
      </section>
    </>
  );
};
