import { Button, Htag } from "components";
import { CoachProps } from "./Coach.props";
import { useNavigate } from "react-router-dom";
import s from "./coach.module.css";

export const Coach = ({ coach }: CoachProps): JSX.Element => {
  const navigation = useNavigate();
  
  return (
    <div className={s.coachItem}>
		<div className={s.coachImg}><img src={coach.image} alt="" /></div>
      
      <Htag tag="h4">
        {coach.name}, {coach.position}
      </Htag>
      <div>
        <p>{coach.description}</p>
      </div>
      <Button
        apearance="dark"
        onClick={() => {
          navigation(`${coach.id}/booking`);
        }}
		size="M"
      >
        Book session with {coach.name}
      </Button>
    </div>
  );
};
