import { ChangeEvent, useEffect, useState } from "react";
import s from "./InputFile.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { InputFileProps } from "./InputFile.props";
import {
  FileProps,
  IAttach,
} from "../../store/system_resources/system_resource.interface";
import { useAppDispatch } from "../../utils/hooks";
import {
  attachType,
  deleteAttach,
  deleteTicketAttach,
} from "../../store/attachments/attachments-thunk";
import { Button, DropDownArrowIcon, Htag, ModalStyled } from "components";
import { MenuItem, TextField } from "@mui/material";

interface IInput {
  // files: Partial<FileProps>[],
  ticketAttach: IAttach[];
  templateType: number;
  showUploadDialog: boolean;
  selectedFile: FileProps | null;
  oldFiles: Partial<FileProps>[];
}

export const InputFileComponent = ({
  handleFiles,
  files,
  id,
  type,
  ...props
}: InputFileProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [file, setFile] = useState<Partial<FileProps>[]>([]);

  const [inputSettings, setInputSettings] = useState<IInput>({
    // file: [],
    ticketAttach: [],
    templateType: 0,
    showUploadDialog: false,
    selectedFile: null,
    oldFiles: [],
  });

  const handleselectedFile = (event: any) => {
	
    if (type !== "ticket") {
      setFile((old) => [...old, event.target.files[0]]);
      handleFiles(event.target.files[0]);
    } else {
      setInputSettings({
        ...inputSettings,
        selectedFile: event.target.files[0],
      });
    }
  };

  const removeFile = (index: number): void => {
    if (type == "ticket") {
      const updatedTicketAttach = inputSettings.ticketAttach.filter(
        (x, i) => i !== index
      );

      setInputSettings({
        ...inputSettings,
        ticketAttach: updatedTicketAttach,
      });
    } else {
      setFile((old) => old.filter((x, i) => i !== index));
    }
  };

  const removeOldFile = (id: number, index: number): void => {
    if (type && type == "ticket") {
      dispatch(deleteTicketAttach(id))
        .unwrap()
        .then(() => {
          const updateOldFiles = inputSettings.oldFiles.filter(
            (x, i) => i !== index
          );

          setInputSettings({
            ...inputSettings,
            oldFiles: updateOldFiles,
          });
        });
    } else {
      dispatch(deleteAttach(id))
        .unwrap()
        .then(() => {
          const updateOldFiles = inputSettings.oldFiles.filter(
            (x, i) => i !== index
          );

          setInputSettings({
            ...inputSettings,
            oldFiles: updateOldFiles,
          });
        });
    }
  };

  useEffect(() => {
    if (files) {
      setInputSettings((curr) => ({
        ...curr,
        oldFiles: files,
        ticketAttach: [],
      }));
    } else {
      setInputSettings({
        ...inputSettings,
        ticketAttach: [],
      });
    }

    setFile([]);

  }, [files]);

  const handleTypeChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    setInputSettings({
      ...inputSettings,
      templateType: Number(event.target.value),
    });
  };

  const handleSettingTemplate = () => {
    if (!inputSettings.selectedFile) return;

    const newTicketAttach = [
      ...inputSettings.ticketAttach,
      {
        file: inputSettings.selectedFile,
        type: inputSettings.templateType.toString(),
      },
    ];

    handleFiles({
      file: inputSettings.selectedFile,
      type: inputSettings.templateType.toString(),
    });

    setInputSettings((currentState) => ({
      ...currentState,
      showUploadDialog: false,
      ticketAttach: newTicketAttach,
    }));
  };

  return (
    <div {...props}>
      <input
        type="file"
        name="file"
        id={id}
        onChange={handleselectedFile}
        style={{ display: "none" }}
      />

      {type == "ticket" ? (
        <Button
          apearance="secondary"
          onClick={(e) => {
            e.preventDefault();

            setInputSettings((currentState) => ({
              ...currentState,
              showUploadDialog: true,
            }));
          }}
        >
          Add Template
        </Button>
      ) : (
        <label className={s.uploadButton} htmlFor={id}>
          <div className={s.uploadFileBtn}>Upload file</div>
        </label>
      )}
      {inputSettings.oldFiles.length !== 0 && (
        <div className={s.attachList}>
          {inputSettings.oldFiles.map((x, index) => (
            <p key={index}>
              {x.name} -{" "}
              {type !== "ticket" ? x.type : attachType[Number(x.type)]}
              <CloseIcon onClick={() => x.id && removeOldFile(x.id, index)} />
            </p>
          ))}{" "}
        </div>
      )}
      {file.length !== 0 && (
        <div className={s.attachList}>
          {file.map(
            (x, index) =>
              x && (
                <p key={index}>
                  {x.name} - {x.type}
                  <CloseIcon onClick={() => removeFile(index)} />
                </p>
              )
          )}
        </div>
      )}
      {inputSettings.ticketAttach.length !== 0 && (
        <div className={s.attachList}>
          {inputSettings.ticketAttach.map(
            (x, index) =>
              x && (
                <p key={index}>
                  {x.file.name} - {attachType[Number(x.type)]}
                  <CloseIcon onClick={() => removeFile(index)} />
                </p>
              )
          )}
        </div>
      )}
      <ModalStyled
        open={inputSettings.showUploadDialog}
        close={() =>
          setInputSettings((currentState) => ({
            ...currentState,
            showUploadDialog: false,
          }))
        }
      >
        <Htag tag="h3">Upload template</Htag>
        <div className={s.selectType}>
          <Htag tag="h4">Select type:</Htag>
          <TextField
            variant="outlined"
            name="type"
            id="type"
            fullWidth
            select
            className={s.selectStatus}
            label={inputSettings.templateType === null ? "Type is…" : ""}
            defaultValue={inputSettings.templateType}
            InputLabelProps={{ shrink: false, disableAnimation: true }}
            value={inputSettings.templateType}
            onChange={handleTypeChange}
            sx={{
              "& .MuiSelect-iconOpen": { transform: "rotate(90deg)" },
              "& .MuiSelect-icon": { top: "16px" },
              "& .Mui-focused": {
                color: "#102D3E",
              },
              "& .Mui-focused.MuiOutlinedInput-root": {
                border: "2px solid #91CCD1;",
              },
            }}
            SelectProps={{
              IconComponent: (props) => <DropDownArrowIcon {...props} />,
            }}
          >
            {attachType.map((option, index) => (
              <MenuItem key={index} value={index}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className={s.selectType}>
          <Htag tag="h4">Select template</Htag>
          <label className={s.uploadButton} htmlFor={id}>
            <div className={s.uploadFileBtn}>Upload file</div>
          </label>
        </div>
        <div className={s.fileName}>
          <p>
            <strong>File Name:</strong> {inputSettings.selectedFile?.name}
          </p>
        </div>

        <Button apearance="default" onClick={handleSettingTemplate}>
          Done
        </Button>
      </ModalStyled>
    </div>
  );
};
