// import { Space } from "./spaces-interface";

import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ALL_TICKETS,
  CREATE_COMMENT,
  CREATE_TICKET,
  DELETE_COMMENT,
  DELETE_TICKET,
  GET_MILESTONE,
  GET_SINGLE_TICKET,
  SET_TICKET_STATUS,
  UPDATE_COMMENT,
  UPDATE_TICKET,
} from "../config";
import { IInitState } from "../root-interface";
import { IComment, ITicket, ResponseTypeTickets } from "./tickets-interface";
import { setToken } from "../userInfo/userInfo-reducer";

export const loadTickets = createAsyncThunk<
  ResponseTypeTickets[],
  number,
  { getState: IInitState; rejectValue: string }
>(
  "loadTickets",
  async function (space_id, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + userState.token,
        responseType: "json",
      },
    };

    const response = await fetch(ALL_TICKETS(space_id), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
        if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          if (result.errors) {
            return rejectWithValue(result.errors[0]);
          }
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as ResponseTypeTickets[];
    }
  }
);

export const loadMilestoneTickets = createAsyncThunk<
  ITicket[],
  number,
  { getState: IInitState; rejectValue: string }
>(
  "@@spaces/milestone",
  async function (id, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;
    const header = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + userState.token,
        responseType: "json",
      },
    };
    const response = await fetch(GET_MILESTONE(id), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
        if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as ITicket[];
    }
  }
);

export const loadSingleTicket = createAsyncThunk<
  ITicket,
  string,
  { getState: IInitState; rejectValue: string }
>(
  "@@tickets/loadTicket",
  async function (ticket_id, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + userState.token,
        responseType: "json",
      },
    };

    const response = await fetch(GET_SINGLE_TICKET(ticket_id), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
        if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          if (result.errors) {
            return rejectWithValue(result.errors[0]);
          }
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as ITicket;
    }
  }
);

export const setNewTicketStatus = createAsyncThunk<
  ITicket,
  { ticket_id: number | undefined; status: number; sort: number | undefined },
  { getState: IInitState; rejectValue: string }
>(
  "@@tickets/setStatus",
  async function (
    { ticket_id, status, sort },
    { getState, rejectWithValue, dispatch }
  ) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + userState.token,
        responseType: "json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ status: Number(status), sort: sort }),
    };

    const response = await fetch(SET_TICKET_STATUS(ticket_id), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
        if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          if (result.errors) {
            return rejectWithValue(result.errors[0]);
          }
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as ITicket;
    }
  }
);

export const createNewTicket = createAsyncThunk<
  { success: string; ticket: ITicket },
  { space_id: number | undefined; ticket_data: ITicket },
  { getState: IInitState; rejectValue: string }
>(
  "@@tickets/createTicket",
  async function (
    { space_id, ticket_data },
    { getState, rejectWithValue, dispatch }
  ) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + userState.token,
        responseType: "json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(ticket_data),
    };

    const response = await fetch(CREATE_TICKET(space_id), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
        if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          if (result.errors) {
            return rejectWithValue(result.errors[0]);
          }
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as { success: string; ticket: ITicket };
    }
  }
);

export const updateTicket = createAsyncThunk<
  { success: string },
  { ticket_id: number; ticket: ITicket },
  { getState: IInitState; rejectValue: string }
>(
  "@@tickets/updateTicket",
  async function (
    { ticket_id, ticket },
    { getState, rejectWithValue, dispatch }
  ) {
    const { userState } = getState() as IInitState;
    const header = {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + userState.token,
        responseType: "json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(ticket),
    };

    const response = await fetch(UPDATE_TICKET(ticket_id), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
        if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          if (result.errors) {
            return rejectWithValue(result.errors[0]);
          }
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as { success: string };
    }
  }
);

export const deleteTicket = createAsyncThunk<
  { ticket_id: number },
  number | undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@tickets/deleteTicket",
  async function (ticket_id, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;
    if (!userState.token) dispatch(setToken(null));

    const header = {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + userState.token,
        responseType: "json",
      },
    };
    const response = await fetch(DELETE_TICKET(ticket_id), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          if (result.errors) {
            return rejectWithValue(result.errors[0]);
          }
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as { ticket_id: number };
    }
  }
);

export const createComment = createAsyncThunk<
  { success: string; comment: IComment },
  IComment,
  { getState: IInitState; rejectValue: string }
>(
  "@@tickets/createComment",
  async function (
    { ticket_id, comment },
    { getState, rejectWithValue, dispatch }
  ) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + userState.token,
        responseType: "json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ticket_id: ticket_id, comment: comment }),
    };

    const response = await fetch(CREATE_COMMENT(), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
        if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          if (result.errors) {
            return rejectWithValue(result.errors[0]);
          }
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as { success: string; comment: IComment };
    }
  }
);

export const editComment = createAsyncThunk<
  { success: string },
  { comment_id: number; comment: string },
  { getState: IInitState; rejectValue: string }
>(
  "@@tickets/deleteComment",
  async function (
    { comment, comment_id },
    { getState, rejectWithValue, dispatch }
  ) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + userState.token,
        responseType: "json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ comment: comment }),
    };
    const response = await fetch(UPDATE_COMMENT(comment_id), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
        if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          if (result.errors) {
            return rejectWithValue(result.errors[0]);
          }
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as { success: string };
    }
  }
);

export const deleteComment = createAsyncThunk<
  { success: string },
  number,
  { getState: IInitState; rejectValue: string }
>(
  "@@tickets/deleteComment",
  async function (comment_id, { getState, rejectWithValue, dispatch,  }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + userState.token,
        responseType: "json",
      },
    };
    const response = await fetch(DELETE_COMMENT(comment_id), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
		if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          if (result.errors) {
            return rejectWithValue(result.errors[0]);
          }
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as { success: string };
    }
  }
);
