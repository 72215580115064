import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
} from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useState } from "react";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import {
  Button,
  Checked_folderIcon,
  ClockIcon,
  CommentIcon,
  DeleteIcon,
  Htag,
  ResourceIcon,
  TemplateIcon,
} from "../..";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import dayjs from "dayjs";
import { TicketViewProps } from "./TicketView.props";
import s from "./Ticket.module.css";
import cn from "classnames";
import { useAppDispatch } from "../../../utils/hooks";
import { createComment } from "../../../store/tickets/tickets-thunk";

import ReactHtmlParser from "html-react-parser";
import { NavLink } from "react-router-dom";
import { downloadAttach } from "../../../controlls/tickets-controll";
import { FileProps } from "../../../store/system_resources/system_resource.interface";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { IComment } from "../../../store/tickets/tickets-interface";
import { CommentItem } from "../../Comment/CommentItem.component";

export const TicketItemView = ({
  complete,
  deleteItem,
  ticket,
}: TicketViewProps): JSX.Element => {
  const [open, setOpen] = useState<boolean>(false);

  const href = window.location.pathname;

  const link = ticket.special_ticket_id
    ? `special_ticket_id=${ticket.special_ticket_id}`
    : `id=${ticket.id}`;
  const dispatch = useAppDispatch();
  const [comment, setComment] = useState<string>("");

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (ticket.id)
      dispatch(createComment({ ticket_id: ticket.id, comment: comment }))
        .unwrap()
        .then(() => {
          setComment("");
        });
  };

  const changeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setComment(e.target.value);
  };

  return (
    <div className={s.ticketViewWrap}>
      <header className={s.ticketViewHeader}>
        <Htag tag="h3" className={s.ticketTitle}>
          <Checked_folderIcon /> {ticket.title}
        </Htag>

        <span>
          <ClockIcon /> Updated {dayjs(ticket.updated_at).fromNow()}
        </span>
      </header>
      <div className={s.ticket_desc}>
        <Htag tag="h4">
          <ResourceIcon /> Description
        </Htag>
        <div className={cn(s.ticket_desc_content)}>
          {ticket.short_description &&
            ReactHtmlParser(ticket.short_description)}
        </div>
        {ticket.short_description && (
          <div className={s.reading_time}>
            <NavLink to={`${href}/${link}`} className={cn(s.openFulltext)}>
              <RemoveRedEyeOutlinedIcon />
              <span className="hover-underline-animation_back">
                Read the full resource
              </span>
            </NavLink>
            {ticket.reading_time}
          </div>
        )}
      </div>
      {ticket.attachments && ticket?.attachments.length > 0 && (
        <div className={s.templates}>
          <Htag tag="h4">
            <TemplateIcon /> Templates to help you
          </Htag>
          {ticket.attachments.map((template: FileProps, index: number) => (
            <div
              onClick={() => {
                if (ticket.special_ticket_id) {
                  downloadAttach(
                    template.id,
                    ticket.special_ticket_id,
                    template.name
                  );
                }
              }}
              className={s.templateItem}
              key={index}
            >
              <FileDownloadOutlinedIcon />
              {template.name}
            </div>
          ))}
        </div>
      )}

      {/* <div className={s.commentsWrap}>
        <Htag tag="h4">
          <CommentIcon /> My notes
        </Htag>
        {ticket.comments?.map(
          (item: IComment) =>
            ticket.id && (
              <CommentItem key={ticket.id} ticket_id={ticket.id} item={item} />
            )
        )}

        <TextField
          id="outlined-multiline-flexible"
          fullWidth
          label="Add a comment or update…"
          multiline
          value={comment}
          onChange={changeValue}
          maxRows={4}
          className={s.addComment}
        />

        <Button
          className={cn(s.addBtn)}
          onClick={onSubmit}
          apearance="primary-light"
        >
          <ArrowUpwardIcon />
        </Button>
      </div> */}

      <List
        component="nav"
        className={s.bottomNav}
        aria-labelledby="nested-list-subheader"
      >
        {ticket.status !== 3 && (
          <ListItemButton onClick={() => complete(1)} className={s.ticketBtn}>
            <ListItemIcon className={s.navIcon}>
              <CheckBoxIcon className={s.completeIcon} />
            </ListItemIcon>
            <ListItemText primary="Done" />
          </ListItemButton>
        )}

        <ListItemButton onClick={deleteItem} className={s.ticketBtn}>
          <ListItemIcon className={s.navIcon}>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText className={s.deleteText} primary="Delete" />
        </ListItemButton>
      </List>
    </div>
  );
};
