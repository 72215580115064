

export class Article {
  id: number;
  reading_time: string;
  title: string;
  content: string;
  constructor(title = "", content = "", reading_time = "") {
    this.title = title;
    this.content = content;
    this.reading_time = reading_time;
  }

  addToBookmark(id: number) {
    console.log(id);
  }
}

export class BlogPost extends Article {
  private _tags: string[];
  categories: string[];
  image: Blob | null | string;

  constructor(
    title = "",
    content = "",
    reading_time = "",
    categories = [],
    tags = [],
    image = ""
  ) {
    super(title, content, reading_time);
    this.categories = categories;
    this.image = image;
    this._tags = tags;
  }

  get tags() {
    return this._tags;
  }

  set tags(tags) {
    this._tags = tags;
  }
}
