import axios from "axios";
import { useEffect } from "react";

import { Htag } from "components";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { reset, setBookmarks } from "store/bookmarks/bookmarks-reducer";
import { GET_ALL_BOOKMARKS } from "store/config";

import s from "./SavedArticles.module.css";
import { ResourcesListComponent } from "../Spaces/SResources/ResourcesList.component";
import { CaseStudyList } from "../CaseStudies/CaseStudyList.component";

export const SavedArticles = (): JSX.Element => {
  const userToken = useAppSelector((state) => state.userState.token);
  const { wtools, wresources, sresource, cstudies } = useAppSelector(
    (state) => state.bookmarksState
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    axios
      .get(GET_ALL_BOOKMARKS(), {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + userToken,
        },
        responseType: "json",
      })
      .then((res) => {
        dispatch(reset());
        dispatch(setBookmarks(res.data));
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className={s.savedResourcesWrap}>
      <header className={s.saveBookmarksHeader}>
        <Htag tag="h2">Saved Resources</Htag>
        <p>
          Here are the resources that you have saved. If you’d like to remove
          one from this saved resources area, click on it and press ‘save
          resource’.
        </p>
      </header>

      {wresources.length > 0 && (
        <div className={s.bookmarks_block}>
          <header>
            <Htag tag="h2standard">Saved wellbeing guides</Htag>
          </header>
          <ResourcesListComponent
            show_in_row={4}
            showTgas={false}
            resources={wresources}
          />
        </div>
      )}

      {wtools.length > 0 && (
        <div className={s.bookmarks_block}>
          <header>
            <Htag tag="h2standard">Saved wellbeing tools</Htag>
          </header>

          <ResourcesListComponent
            show_in_row={4}
            showTgas={false}
            resources={wtools}
          />
        </div>
      )}

      {sresource.length > 0 && (
        <div className={s.bookmarks_block}>
          <header>
            <Htag tag="h2standard">Saved guides</Htag>
          </header>

          <ResourcesListComponent
            show_in_row={4}
            showTgas={true}
            resources={sresource.slice(0, 4)}
          />
        </div>
      )}

      {cstudies.length > 0 && (
        <div className={s.bookmarks_block}>
          <header>
            <Htag tag="h2standard">
              Consider options with real-life examples
            </Htag>
            <p>
              Each case study highlights multiple options for how these families
              approached their separation.
            </p>
          </header>
          <CaseStudyList data={cstudies} />
        </div>
      )}
    </div>
  );
};
