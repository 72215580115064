import { AddBookmark, ClockIcon, Htag } from "components";
import ReactHtmlParser from "html-react-parser";
import { useAppDispatch } from "utils/hooks";
import { CaseStudySummaryUser } from "widgets/caseStudySummary/caseStudySummaryUser.component";
import s from "../../Dashboard/CaseStudies/CaseStudies.module.css";
import article from "components/ArticleContent/ArticleContent.module.css";
import cn from "classnames";

import { OptionsWidgetUser } from "../../AdminDashboard/CaseStudies/Options/OptionsWidgetUser.component";
import { ICaseStudies } from "store/caseStudy/caseStudy.inderface";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { get_cs } from "store/caseStudy/caseStudy_thunk";
import { ExtraCaseStudyUser } from "widgets/extraCS/ExtraCaseStudyUser.component";
import { CaseStudy } from "models/CaseStudy.model";

export const CaseStudyUser = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [cs, setCS] = useState(new CaseStudy() as ICaseStudies);
  useEffect(() => {
    if (!id) return;

    dispatch(get_cs(id))
      .unwrap()
      .then((resp) => {
        setCS(resp);
      });
  }, []);

  return (
    <div className={s.csWrap}>
      <Htag tag="h2">
        <span> {cs.title}</span>
      </Htag>

      <div className={article.meta}>
        <div className={article.reding_time}>
          {cs?.reading_time && (
            <>
              <ClockIcon /> {cs?.reading_time}
            </>
          )}
        </div>

        {cs && cs.id && (
          <div className={article.saveMark}>
            <AddBookmark
              type={"cstudies"}
              item={{
                title: cs.title,
                id: cs?.id,
                bookmark: cs?.bookmark,
              }}
            />
          </div>
        )}
        <div className={article.typeMark}>Case study</div>
      </div>
      <Htag tag="h4" className={s.subtitle}>
        {cs.subtitle}
      </Htag>
      <div className={s.thumb}>
        <img src={cs.image?.toString()} alt="" />
      </div>
      <Htag tag="h2standard" className={s.cs_title}>
        Their current circumstances
      </Htag>
      <CaseStudySummaryUser data={cs.summary} />
      <div className={cn(article.contentWrap, s.csArticle)}>
        {typeof cs.content == "string" && ReactHtmlParser(cs.content)}
      </div>

      <OptionsWidgetUser
        mainTitle={cs.title}
        summary={cs.summary}
        data={cs.options}
      />
      <ExtraCaseStudyUser data={cs.problems} />
      <span
        onClick={() => navigate(-1)}
        className={cn(s.backBtn, "hover-underline-animation_back")}
      >
        {"<"} Back
      </span>
    </div>
  );
};
