import { Footer, Header } from "layout";
import { FrontOfficeLayoutProps } from "./FrontOfficeLayout.props";
import { Button, GoToTop } from "components";
import { useAppDispatch } from "utils/hooks";
import { resetToDefault } from "store/login/login-reducer";
import { useLocation } from "react-router-dom";

export const FrontOfficeLayout = ({
  children,
  headerData,
}: FrontOfficeLayoutProps): JSX.Element => {
  const dispatch = useAppDispatch();
  //   const location = useLocation();
  const deleteAllCookies = () => {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  };
  const hideSite = () => {
    window.open("https://en.wikipedia.org/wiki/Main_Page", "_newtab");
    location.replace("https://www.bbc.com/news");
    localStorage.removeItem("userToken");
    dispatch(resetToDefault());
  };

  const leaveSite = () => {
    hideSite();
    deleteAllCookies();
  };

  return (
    <>
      <Header data={headerData} />
      <div>{children}</div>
      <Button
        apearance="secondary"
        style={{
          position: "fixed",
          top: "55%",
          right: "-39px",
          padding: "6px 4px",
          transform: "rotate(90deg)",
          width: "115px",
        }}
        onClick={leaveSite}
      >
        Leave site
      </Button>
      <Footer />
      <GoToTop />
    </>
  );
};
