import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  CREATE_NEW_CHALLENGE,
  CREATE_RESOURCE,
  CREATE_TOOL,
  DELETE_CHALLENGE,
  DELETE_RESOURCE,
  DELETE_TOOL,
  GET_ALL_CHALLENGES,
  GET_ALL_FEELLINGS,
  GET_ALL_RESOURCES,
  GET_ALL_TOOLS,
  GET_SINGLE_WELLBEING_RESOURCE,
  GET_SINGLE_WELLBEING_TOOL,
  UPDATE_CHALLENGE,
  UPDATE_FEELING,
  UPDATE_RESOURCE,
  UPDATE_TOOL,
  VIEWED_RESOURCE,
  VIEWED_TOOL,
} from "../config";
import { IInitState } from "../root-interface";
import { Challenge, Feelling, Wellbeing } from "./challenges.interface";

type AddChallengeI = {
  success: string;
  challenge: Challenge;
};

export const loadChallenges = createAsyncThunk<
  {
    visibleFirstTime: boolean;
    challenges: Challenge[];
  },
  undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@wellbeing/allChallenges",
  async function (_, { getState, rejectWithValue }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + userState.token,
        responseType: "json",
      },
    };

    const response = await fetch(GET_ALL_CHALLENGES(), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          if (result.errors) {
            return rejectWithValue(result.errors[0]);
          }
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as {
        visibleFirstTime: boolean;
        challenges: Challenge[];
      };
    }
  }
);

export const addNewChallenge = createAsyncThunk<
  AddChallengeI,
  string,
  { getState: IInitState; rejectValue: string }
>(
  "@@wellbeing/newChallenge",
  async function (title, { getState, rejectWithValue }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + userState.token,
        responseType: "json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ title: title }),
    };
    const response = await fetch(CREATE_NEW_CHALLENGE(), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          if (result.errors) {
            return rejectWithValue(result.errors[0]);
          }
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as AddChallengeI;
    }
  }
);

export const loadFeellings = createAsyncThunk<
  { visibleFirstTime: boolean; feelings: Feelling[] },
  undefined,
  { getState: IInitState; rejectValue: string }
>("@@wellbeing/allFillings", async function (_, { getState, rejectWithValue }) {
  const { userState } = getState() as IInitState;

  const header = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + userState.token,
      responseType: "json",
    },
  };

  const response = await fetch(GET_ALL_FEELLINGS(), header);

  if (!response.ok) {
    if ([401, 403].includes(response.status)) {
      localStorage.removeItem("userToken");
    }
    return response
      .text()
      .then((result) => JSON.parse(result))
      .then((result) => {
        if (result.errors) {
          return rejectWithValue(result.errors[0]);
        }
        return rejectWithValue(result.message);
      });
  } else {
    return (await response.json()) as {
      visibleFirstTime: boolean;
      feelings: Feelling[];
    };
  }
});

export const updateFeeling = createAsyncThunk<
  { success: string; feeling: Feelling },
  { feeling_id: number; description: string },
  { getState: IInitState; rejectValue: string }
>(
  "@@wellbeing/updateFeeling",
  async function ({ feeling_id, description }, { getState, rejectWithValue }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + userState.token,
        responseType: "json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ description: description }),
    };
    const response = await fetch(UPDATE_FEELING(feeling_id), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          if (result.errors) {
            return rejectWithValue(result.errors[0]);
          }
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as { success: string; feeling: Feelling };
    }
  }
);

export const deleteChallenge = createAsyncThunk<
  number,
  number,
  { getState: IInitState; rejectValue: string }
>(
  "@@wellbeing/deleteChallenge",
  async function (challenge_id, { getState, rejectWithValue }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + userState.token,
        responseType: "json",
      },
    };
    const response = await fetch(DELETE_CHALLENGE(challenge_id), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          if (result.errors) {
            return rejectWithValue(result.errors[0]);
          }
          return rejectWithValue(result.message);
        });
    } else {
      return challenge_id;
    }
  }
);

export const updateChallenge = createAsyncThunk<
  { success: string; challenge: Challenge },
  { challenge_id: number; title: string },
  { getState: IInitState; rejectValue: string }
>(
  "@@wellbeing/updateChallenge",
  async function ({ challenge_id, title }, { getState, rejectWithValue }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + userState.token,
        responseType: "json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ title: title }),
    };
    const response = await fetch(UPDATE_CHALLENGE(challenge_id), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          if (result.errors) {
            return rejectWithValue(result.errors[0]);
          }
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as {
        success: string;
        challenge: Challenge;
      };
    }
  }
);

// Resources
export const loadResources = createAsyncThunk<
  Wellbeing[],
  undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@wellbeing/allResources",
  async function (_, { getState, rejectWithValue }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + userState.token,
        responseType: "json",
      },
    };

    const response = await fetch(GET_ALL_RESOURCES(), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          if (result.errors) {
            return rejectWithValue(result.errors[0]);
          }
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as Wellbeing[];
    }
  }
);

export const loadSingleResource = createAsyncThunk<
  Wellbeing,
  number,
  { getState: IInitState; rejectValue: string }
>(
  "@@wellbeing/loadSingleReducer",
  async function (id, { getState, rejectWithValue }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + userState.token,
        responseType: "json",
      },
    };

    const response = await fetch(GET_SINGLE_WELLBEING_RESOURCE(id), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          if (result.errors) {
            return rejectWithValue(result.errors[0]);
          }
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as Wellbeing;
    }
  }
);

export const addResource = createAsyncThunk<
  { success: string; resource: Wellbeing },
  FormData,
  { getState: IInitState; rejectValue: string }
>(
  "@@wellbeing/addResource",
  async function (data, { getState, rejectWithValue }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + userState.token,
      },
      body: data,
    };
    const response = await fetch(CREATE_RESOURCE(), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          if (result.errors) {
            return rejectWithValue(result.errors[0]);
          }
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as {
        success: string;
        resource: Wellbeing;
      };
    }
  }
);

export const deleteResource = createAsyncThunk<
  number,
  number,
  { getState: IInitState; rejectValue: string }
>(
  "@@wellbeing/deleteResource",
  async function (resource_id, { getState, rejectWithValue }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + userState.token,
        responseType: "json",
      },
    };
    const response = await fetch(DELETE_RESOURCE(resource_id), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          if (result.errors) {
            return rejectWithValue(result.errors[0]);
          }
          return rejectWithValue(result.message);
        });
    } else {
      return resource_id;
    }
  }
);

// TOOLS
export const loadTools = createAsyncThunk<
  Wellbeing[],
  undefined,
  { getState: IInitState; rejectValue: string }
>("@@wellbeing/allTools", async function (_, { getState, rejectWithValue }) {
  const { userState } = getState() as IInitState;

  const header = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + userState.token,
      responseType: "json",
    },
  };

  const response = await fetch(GET_ALL_TOOLS(), header);

  if (!response.ok) {
    if ([401, 403].includes(response.status)) {
      localStorage.removeItem("userToken");
    }
    return response
      .text()
      .then((result) => JSON.parse(result))
      .then((result) => {
        if (result.errors) {
          return rejectWithValue(result.errors[0]);
        }
        return rejectWithValue(result.message);
      });
  } else {
    return (await response.json()) as Wellbeing[];
  }
});

export const loadSingleTool = createAsyncThunk<
  Wellbeing,
  number,
  { getState: IInitState; rejectValue: string }
>(
  "@@wellbeing/loadSingleTools",
  async function (id, { getState, rejectWithValue }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + userState.token,
        responseType: "json",
      },
    };

    const response = await fetch(GET_SINGLE_WELLBEING_TOOL(id), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          if (result.errors) {
            return rejectWithValue(result.errors[0]);
          }
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as Wellbeing;
    }
  }
);

export const addTool = createAsyncThunk<
  { success: string; tool: Wellbeing },
  FormData,
  { getState: IInitState; rejectValue: string }
>("@@wellbeing/addTool", async function (data, { getState, rejectWithValue }) {
  const { userState } = getState() as IInitState;

  const header = {
    method: "POST",
    headers: {
      Authorization: "Bearer " + userState.token,
    },
    body: data,
  };
  const response = await fetch(CREATE_TOOL(), header);

  if (!response.ok) {
    if ([401, 403].includes(response.status)) {
      localStorage.removeItem("userToken");
    }
    return response
      .text()
      .then((result) => JSON.parse(result))
      .then((result) => {
        if (result.errors) {
          return rejectWithValue(result.errors[0]);
        }
        return rejectWithValue(result.message);
      });
  } else {
    return (await response.json()) as {
      success: string;
      tool: Wellbeing;
    };
  }
});

export const deleteTool = createAsyncThunk<
  number,
  number,
  { getState: IInitState; rejectValue: string }
>(
  "@@wellbeing/deleteTool",
  async function (tool_id, { getState, rejectWithValue }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + userState.token,
        responseType: "json",
      },
    };
    const response = await fetch(DELETE_TOOL(tool_id), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          if (result.errors) {
            return rejectWithValue(result.errors[0]);
          }
          return rejectWithValue(result.message);
        });
    } else {
      return tool_id;
    }
  }
);

export const updateResource = createAsyncThunk<
  { success: string; resource: Wellbeing },
  { resource_id: number; data: FormData },
  { getState: IInitState; rejectValue: string }
>(
  "@@wellbeing/updateResource",
  async function ({ resource_id, data }, { getState, rejectWithValue }) {
    const { userState } = getState() as IInitState;
   
    const header = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + userState.token,
      },
      body: data,
    };
    const response = await fetch(UPDATE_RESOURCE(resource_id), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          if (result.errors) {
            return rejectWithValue(result.errors[0]);
          }
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as {
        success: string;
        resource: Wellbeing;
      };
    }
  }
);

export const updateTool = createAsyncThunk<
  { success: string; tool: Wellbeing },
  { tool_id: number; data: FormData },
  { getState: IInitState; rejectValue: string }
>(
  "@@wellbeing/updateTool",
  async function ({ tool_id, data }, { getState, rejectWithValue }) {
    const { userState } = getState() as IInitState;
   
    const header = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + userState.token,
      },
      body: data,
    };
    const response = await fetch(UPDATE_TOOL(tool_id), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          if (result.errors) {
            return rejectWithValue(result.errors[0]);
          }
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as { success: string; tool: Wellbeing };
    }
  }
);

// IF RESOURSE/TOOL was readed

export const viewedTool = createAsyncThunk<
  { success: string },
  number,
  { getState: IInitState; rejectValue: string }
>(
  "@@wellbeing/viewedTool",
  async function (tool_id, { getState, rejectWithValue }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + userState.token,
        responseType: "json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ viewed: true }),
    };
    const response = await fetch(VIEWED_TOOL(tool_id), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          if (result.errors) {
            return rejectWithValue(result.errors[0]);
          }
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as { success: string };
    }
  }
);

export const viewedResource = createAsyncThunk<
  { success: string },
  number,
  { getState: IInitState; rejectValue: string }
>(
  "@@wellbeing/viewedResource",
  async function (resource_id, { getState, rejectWithValue }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + userState.token,
        responseType: "json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ viewed: true }),
    };
    const response = await fetch(VIEWED_RESOURCE(resource_id), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          if (result.errors) {
            return rejectWithValue(result.errors[0]);
          }
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as { success: string };
    }
  }
);
