import "../../styles/global-style.css";

import cn from "classnames";
import styles from "./FAQ.module.css";

import { GetInTouch } from "layout/GetInTouch/GetInTouch.component";
import { Accordion, Htag } from "components";

import { useAppSelector } from "utils/hooks";

import { faq } from "pages/Home/faq";
import { Helmet } from 'react-helmet';

function FAQ(): JSX.Element {
  const userToken = useAppSelector((state) => state.userState.token);

  return (
    <>
      <Helmet>
        <title>Our FAQs | SeparateSpace</title>
        <meta
          name="description"
          content="Find answers to common questions about SeparateSpace. Our FAQ section provides helpful information about our services, pricing, and more."
        />
      </Helmet>
      <section className={cn("section", styles.innerPageBlock)}>
        <div className="contentBlock">
          <p>
            Below you'll find answers to the questions we get asked the most
            about SeparateSpace.
          </p>
        </div>
      </section>
      <section className={cn("section", styles.faq)}>
        <Htag tag="h2">FAQ</Htag>
        <Accordion accordionData={faq}></Accordion>
      </section>
      {!userToken && <GetInTouch isHome={false} />}
    </>
  );
}

export default FAQ;
