import { createAsyncThunk } from "@reduxjs/toolkit";
import { GENERAL_ONBG } from "../config";
import { IInitState } from "../root-interface";


import { IUser } from '../userInfo/user-interface';
import { IGeneralQuestionnary } from './generalOnboarding-interface';
import { setToken } from '../userInfo/userInfo-reducer';

export const saveGOnboarding = createAsyncThunk<
  { user: IUser },
  IGeneralQuestionnary,
  { getState: IInitState; rejectValue: string }
>(
  "@@userEntity/saveGOnboarding",
  async function (generalOnBoarding, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + userState.token,
        responseType: "json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(generalOnBoarding),
    };
    const response = await fetch(GENERAL_ONBG(), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
		if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          if (result.errors) {
            return rejectWithValue(result.errors[0]);
          }
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as { user: IUser };
    }
  }
);
