import { createAsyncThunk } from "@reduxjs/toolkit";
import { MEMORY_CREATE } from "store/config";
import { IInitState } from "store/root-interface";

export interface IMemory {
  id?: number;
  name: string;
  value: string;
}


export const createMemory = createAsyncThunk<
  { success: string; memory: IMemory },
  IMemory,
  { getState: IInitState; rejectValue: string }
>(
  "@@memory/createMemory",
  async function ( data, { getState, rejectWithValue }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + userState.token,
        responseType: "json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };
    const response = await fetch(MEMORY_CREATE(), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          if (result.errors) {
            return rejectWithValue(result.errors[0]);
          }
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as {
        success: string;
        memory: IMemory;
      };
    }
  }
);

