import React, { useEffect, useState } from "react";
import cn from "classnames";
import a from "../../layout/header/Header.module.css";

import "../../styles/global-style.css";

import {
  Button,
  Htag,
  OurPurpose,
  OurPurpose2,
  StarDeformate,
} from "components";
import styles from "./Purpose.module.css";
import { NavLink } from "react-router-dom";

function Purpose(): JSX.Element {
  return (
    <>
      <div className={cn(a.header, a.frontOffice)}>
        <div className={cn(a.wrapper, a.mobile_heading)}>
          <div className={cn(a.headling)}>
            <>
              <Htag tag="h3" className={a.heroTitle}>
                Our purpose
              </Htag>
              <p>
                We believe everyone should have access to expert support during
                separation and divorce.
              </p>
            </>
          </div>
          <div className={cn(a.heroImg, "mobile_hide")}>
            <img src="assets/images/purpose_hero.svg" alt="" />
          </div>
        </div>
      </div>
      <section className={cn("section", "innerPageBlock")}>
        <div className="imgBlock">
          <OurPurpose className="mobile_hide" />
        </div>
        <div className="contentBlock">
          <Htag color="var(--middle-blue)" tag="h2">
            Divorce
          </Htag>
          <p>
            According to psychologists, separation and divorce is the second
            most stressful life event a person can go through. We believe the
            end of a relationship shouldn’t be made more difficult by the
            processes required by law. We have designed SeparateSpace with the
            specific aim of reducing the stress involved in divorce.
          </p>

          <p>We want to help.</p>
        </div>
      </section>
      <section className={cn("section", "darkBg", styles.history)}>
        <div className={styles.history_left}>
          <Htag color="var(--third-logo-color)" tag="h2">
            SeparateSpace was founded by two divorce lawyers who between them
            have seen hundreds of couples separate.
          </Htag>
          <OurPurpose2 className="mobile_hide" />
        </div>
        <div className={styles.history_right}>
          <p>
            “We’ve seen first hand the disruption that separation and divorce
            can cause in all aspects of a person’s life. We know what support
            people need. But we’ve also seen the prohibitive cost of that
            support - for an ever increasing mass of people it is completely out
            of reach.
          </p>

          <p>
            SeparateSpace is designed to offer an alternative, affordable way to
            get the help and support you need to feel in control and confident
            about the way through divorce.”
          </p>
          <NavLink to="/team">
            {" "}
            <Button apearance="white">Learn more about our team</Button>
          </NavLink>
        </div>
      </section>
      <section className={cn("section", styles.conclusion)}>
        <StarDeformate />
        <p>
          SeparateSpace equips you to navigate your divorce journey from every
          angle. Whether it’s the logistics of selling the family home, the
          practical arrangements for your children, where to find emotional
          support or the latest expert information on how your children may be
          affected, you can access everything you need when you need it in your
          own space.
        </p>
      </section>
    </>
  );
}

export default Purpose;
