import { createAsyncThunk } from '@reduxjs/toolkit';
import { LOGOUT } from 'store/config';
import { IInitState } from 'store/root-interface';

export const logoutAction = createAsyncThunk<
  { message: string },
  undefined,
  { getState: IInitState; rejectValue: string }
>("@@logout", async function (_, { getState, rejectWithValue }) {
  const { userState } = getState() as IInitState;

  const header = {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + userState.token,
      responseType: "json",
      "Content-Type": "application/json",
    },
    body: null,
  };

  const response = await fetch(LOGOUT(), header);

  if (!response.ok) {
    return rejectWithValue("Server error");
  } else {
    return (await response.json()) as { message: string };
  }
});
