import { Supported } from "components";
import { Htag } from "../../components/Htag/Htag.component";
import { useAppSelector } from "../../utils/hooks";
import s from "./404.module.css";
import { NotFoundProps } from "./404.props";
import cn from "classnames";

export const Page404 = ({
  className,
  ...props
}: NotFoundProps): JSX.Element => {
  const { loading } = useAppSelector((state) => state.pages);
  return (
    <>
      {!loading && (
        <div className={cn(s.p404, className)} {...props}>
          <Supported />
          <Htag tag="h1H">COMING SOON</Htag>
          <p>Page under development</p>
        </div>
      )}
    </>
  );
};
