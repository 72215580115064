import { Features } from 'components';
import cn from 'classnames';
import s from './Home.module.css';
import { sunday } from './Home.images';
export const GreatingSection = () => {
	return (
    <section className={cn("centred", s.header_2)}>
      <div className={s.header_2_wrapper}>
        <p>
          Created by leading experts across law, finance, children and
          wellbeing, SeparateSpace gives you the personalised, specialist
          support you need to get through divorce.
        </p>
        <Features />
        <div className={s.header_2_img_wrap}>
          <small>As seen in</small>
          <img className={s.header_2_img} src={sunday} alt="" />
        </div>
      </div>
    </section>
  );
}