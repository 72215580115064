import { ArticleContentProps } from "./ArticleContent.props";
import ReactHtmlParser from "html-react-parser";
import cn from "classnames";
import s from "./ArticleContent.module.css";
import { Htag } from "../Htag/Htag.component";
import { ClockIcon, JournalIcon, TemplateIcon } from "../Icons/Icons.component";
import { AddBookmark } from "../AddBookmark/AddBookmark.component";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import { FileProps } from "../../store/system_resources/system_resource.interface";
import axios from "axios";
import { DOWNLOAD_ATTACH } from "../../store/config";
import { viewedResource } from "../../store/challenges/challenges-thunk";
import { useEffect } from "react";
import { setSourceVisible } from "../../store/challenges/challenges-reducer";
import { useNavigate } from "react-router-dom";
import { downloadFile } from "utils/file.download";

export const ArticleContent = ({
  type,
  data,
}: ArticleContentProps): JSX.Element => {
  const href: string = window.location.pathname;
  const dispatch = useAppDispatch();
  const { token } = useAppSelector((state) => state.userState);

  const navigate = useNavigate();

  useEffect(() => {
    if (href.includes("wresources") && data?.id) {
      dispatch(viewedResource(data?.id))
        .unwrap()
        .then(() => {
          dispatch(setSourceVisible({ id: data.id, type: "resource" }));
        });
    }
  }, []);

  const getTypeArticle = (): "" | "Guide" | "Wellbeing" => {
    if (data.type && data.type.includes("resource")) return "Guide";
    if (type.includes("resource")) return "Guide";
    if (type.includes("tool")) return "Wellbeing";
    return "";
  };

  const downloadAttach = (id: number, name: string) => {
    axios
      .get(DOWNLOAD_ATTACH(id), {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        responseType: "blob",
      })
      .then((response) => {
        downloadFile(response.data, name);
      });
  };

  return (
    <article className={s.articleContent}>
      <Htag tag="h2" className={s.title}>
        {data?.title}
      </Htag>
      <div className={s.meta}>
        {data?.reading_time && (
          <div className={s.reding_time}>
            <ClockIcon /> {data?.reading_time}
          </div>
        )}

        {data?.need_journal && data?.need_journal !== 0 && (
          <div className={s.reding_time}>
            <JournalIcon className={s.JournalIcon} /> Journal
          </div>
        )}

        {data.attachments && data.attachments.length > 0 && (
          <div className={s.templatesMark}>
            <TemplateIcon /> Templates
          </div>
        )}

        {data && data.type !== "resource" && (
          <div className={s.saveMark}>
            <AddBookmark
              type={type}
              item={{
                title: data.title,
                id: data?.id,
                bookmark: data?.bookmark,
              }}
            />
          </div>
        )}
        <div className={s.typeMark}>{getTypeArticle()}</div>
      </div>
      {data.image && (
        <div className={s.imageContainer}>
          <img src={data?.image} alt={data.title} />
        </div>
      )}
      <div className={cn(s.contentWrap)}>
        {typeof data?.content == "string" && ReactHtmlParser(data.content)}
      </div>
      {data.attachments && data?.attachments.length > 0 && (
        <div className={s.templates}>
          <h3>
            <TemplateIcon /> Templates to help you
          </h3>
          {data.attachments.map((template: FileProps, index: number) => (
            <div
              onClick={() => {
                downloadAttach(template.id, template.name);
              }}
              className={s.templateItem}
              key={index}
            >
              <FileDownloadOutlinedIcon />
              {template.name}
            </div>
          ))}
        </div>
      )}
      <span
        onClick={() => navigate(-1)}
        className={cn(s.backBtn, "hover-underline-animation_back")}
      >
        {"<"} Back
      </span>
    </article>
  );
};
