import { NavLink, Outlet } from "react-router-dom";
import { setToken, setUsedData } from "store/userInfo/userInfo-reducer";
import { useEffect, useState } from "react";
import axios from "axios";
import { useAppDispatch, useAppSelector } from "utils/hooks";

const ProtectedRoleRoute = ({
  isAllowed,
  redirectPath = "/landing",
  children,
}: {
  isAllowed: any;
  redirectPath?: string;
  children?: JSX.Element;
}): JSX.Element => {
  const userToken = useAppSelector((state) => state.userState.token);
  const userRole = useAppSelector((state) => state.userState.user.role);

  const [ApiError, setApiError] = useState("");
  const dispatch = useAppDispatch();
  //   if (!localStorage.getItem("userToken")) dispatch(setToken(null));
  useEffect(() => {
    if (userToken && !userRole) {
      axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}/is-user-logged`,
          {},
          {
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + userToken,
            },
          }
        )
        .then((res) => {
          dispatch(setUsedData(res.data.user));
        })
        .catch((error) => {
          setApiError(error.response.data.message);
        });
    }
  }, [userRole, userToken]);

  if (!isAllowed) {
    return (
      <div className="unauthorized">
        <h1>Unauthorized :(</h1>
        {ApiError && <h3>{ApiError}</h3>}
        <span>
          <NavLink to="/">Login</NavLink> to gain access
        </span>
      </div>
    );
  }

  return children ? children : <Outlet />;
};
export default ProtectedRoleRoute;
