import { createAsyncThunk } from '@reduxjs/toolkit';
import { FORGO_PASS, RESET_PASS } from 'store/config';
import { IInitState } from 'store/root-interface';

export const forgotPassAction = createAsyncThunk<
  { message: string },
  string,
  { getState: IInitState; rejectValue: string }
>("@@forgotPass", async function (email, { getState, rejectWithValue }) {
  const { userState } = getState() as IInitState;

  const header = {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + userState.token,
      responseType: "json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email: email }),
  };
  const response = await fetch(FORGO_PASS(), header);

  if (!response.ok) {
    return response
      .text()
      .then((result) => JSON.parse(result))
      .then((result) => {
        return rejectWithValue(result.errors[0].email[0]);
      });
  } else {
    return (await response.json()) as { message: string };
  }
});

export const changePassAction = createAsyncThunk<
  { message: string },
  {
    code: string;
    password: string;
    password_confirmation: string;
  },
  { getState: IInitState; rejectValue: string }
>("@@changePass", async function (data, { getState, rejectWithValue }) {
  const { userState } = getState() as IInitState;

  const header = {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + userState.token,
      responseType: "json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };

  const response = await fetch(RESET_PASS(), header);

  if (!response.ok) {
    return response
      .text()
      .then((result) => JSON.parse(result))
      .then((result) => {
        if (result.errors) {
          return rejectWithValue(result.errors[0].code[0]);
        } else {
          return rejectWithValue(result.error);
        }
      });
  } else {
    return (await response.json()) as { message: string };
  }
});
