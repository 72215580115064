import { createAsyncThunk } from "@reduxjs/toolkit";
import { IInitState } from "../root-interface";
import {
  CREATE_PAGE_TEMPLATE,
  DELETE_PAGE_TEMPLATE,
  GET_ALL_PAGES,
  GET_PAGE,
  UPDATE_PAGE_TEMPLATE,
} from "../config";
import { Page } from "./simplePages-inderfaces";

export const loadSimplePages = createAsyncThunk<
  Page[],
  string | null | undefined,
  { getState: IInitState; rejectValue: string }
>("@@pages/loadPages", async function (_, { rejectWithValue }) {
  const header = {
    method: "GET",
    headers: {
      Accept: "application/json",
      responseType: "json",
    },
  };

  const response = await fetch(GET_ALL_PAGES(), header);

  if (!response.ok) {
    return response
      .text()
      .then((result) => JSON.parse(result))
      .then((result) => {
        return rejectWithValue(result.message);
      });
  } else {
    return (await response.json()).data as Page[];
  }
});

export const loadSinglePage = createAsyncThunk<
  Page,
  number,
  { getState: IInitState; rejectValue: string }
>("@@pages/loadSinglePage", async function (page_id, { rejectWithValue }) {
  const header = {
    method: "GET",
    headers: {
      Accept: "application/json",
      responseType: "json",
    },
  };

  const response = await fetch(GET_PAGE(page_id), header);

  if (!response.ok) {
    return response
      .text()
      .then((result) => JSON.parse(result))
      .then((result) => {
        return rejectWithValue(result.message);
      });
  } else {
    return (await response.json()) as Page;
  }
});

export const createNewPage = createAsyncThunk<
  { success: string; page: Page },
  FormData,
  { getState: IInitState; rejectValue: string }
>(
  "@@pages/createPage",
  async function (page_data, { getState, rejectWithValue }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + userState.token,
      },
      body: page_data,
    };

    const response = await fetch(CREATE_PAGE_TEMPLATE(), header);

    if (!response.ok) {
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as { success: string; page: Page };
    }
  }
);

export const updatePage = createAsyncThunk<
  { success: string; page: Page },
  { page_id: number; page_data: FormData },
  { getState: IInitState; rejectValue: string }
>(
  "@@pages/updatePage",
  async function ({ page_id, page_data }, { getState, rejectWithValue }) {
    const { userState } = getState() as IInitState;
    const header = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + userState.token,
      },
      body: page_data,
    };

    const response = await fetch(UPDATE_PAGE_TEMPLATE(page_id), header);

    if (!response.ok) {
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as { success: string; page: Page };
    }
  }
);

export const deleteSimplePage = createAsyncThunk<
  number,
  number,
  { getState: IInitState; rejectValue: string }
>(
  "@@pages/deletePage",
  async function (page_id, { getState, rejectWithValue }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + userState.token,
        responseType: "json",
      },
    };
    const response = await fetch(DELETE_PAGE_TEMPLATE(page_id), header);

    if (!response.ok) {
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          return rejectWithValue(result.message);
        });
    } else {
      return page_id;
    }
  }
);
