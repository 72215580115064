import { createAsyncThunk } from "@reduxjs/toolkit";
import { IInitState } from "../root-interface";
import { GET_ALL_RESULTS } from "../config";
import { ISearchResult } from "./search-interface";
import { setToken } from '../userInfo/userInfo-reducer';

export const get_search_result = createAsyncThunk<
  ISearchResult[],
  string,
  { getState: IInitState; rejectValue: string }
>("@@search", async function (search, { getState, rejectWithValue, dispatch }) {
  const { userState } = getState() as IInitState;

  const header = {
    method: "GET",
    headers: {
      Accept: "application/json",
      responseType: "json",
      Authorization: "Bearer " + userState.token,
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(GET_ALL_RESULTS(search), header);

  if (!response.ok) {
    if ([401, 403].includes(response.status)) {
      localStorage.removeItem("userToken");
      if (userState.token) dispatch(setToken(null));
    }
    return response
      .text()
      .then((result) => JSON.parse(result))
      .then((result) => {
        return rejectWithValue(result.message);
      });
  } else {
    return (await response.json()) as ISearchResult[];
  }
});
