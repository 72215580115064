import { createAsyncThunk } from "@reduxjs/toolkit";
import { SEND_EBOOKING } from 'store/config';
import { IInitState } from 'store/root-interface';
import { setToken } from 'store/userInfo/userInfo-reducer';

export const sendTriggerByBooking = createAsyncThunk<
  { success: string;},
  undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@coaching/triggerAfterBooking",
  async function ( _, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + userState.token,
      },
    };

    const response = await fetch(SEND_EBOOKING(), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
        if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as {
        success: string;
      };
    }
  }
);
