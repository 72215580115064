import { Htag } from "components";
import coach from "./coach.json";
import { Coach } from "./Coach/Coach.component";
import cn from "classnames";
import s from "./coaching.module.css";

export const CoachList = (): JSX.Element => {
  return (
    <section className={cn(s.coaching_section, "section")}>
      <Htag tag="h2">Book your expert</Htag>
      <figure className="image_container">
        <img src="../../assets/images/coaching.webp" alt="coaching" />
      </figure>

      <p>
        Stuck with your divorce? Our experienced experts can help. Book a
        confidential session to get your questions answered and move forward.
      </p>
      <Htag tag="h3">How our divorce experts can help</Htag>
      <p>
        Dealing with divorce without the support of a law firm can be
        overwhelming and stressful. That’s why SeparateSpace offers one-to-one
        sessions with divorce experts, in addition to our legal platform. Our
        divorce experts offer a private space for you to talk about your
        concerns and get help with navigating your next steps.
      </p>
      <p>Here are some of the ways our divorce experts can help:</p>
      <ul className="ul">
        <li>
          they can help you work out where you are and where you want to be.
        </li>
        <li>they can help you understand your financial picture.</li>
        <li>they can help you think about how to move forward.</li>
      </ul>
      <Htag tag="h3">Who are the divorce experts</Htag>
      <p>
        Each of our divorce experts has over 10 years of experience supporting
        people as they separate. They used to work as family lawyers and now use
        their extensive experience to give practical and emotional support to
        people at all stages of their divorce.
      </p>
      <Htag tag="h3">How it works</Htag>
      <ol className="ol">
        <li>
          Select whether you'd like your 15 minute free intro call, or a full
          hour session.
        </li>
        <li>Book a convenient time.</li>
        <li>
          Meet your divorce expert online. Get answers to your questions and
          move forward with your divorce.
        </li>
      </ol>
      <Htag tag="h3">Your expert</Htag>
      <Coach coach={coach} />
    </section>
  );
};
