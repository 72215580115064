import { useRef, useState } from "react";
import s from "../SpecialSources.module.css";
import st from "./Blog.module.css";
import * as yup from "yup";
import { BlogPost } from "models/Article.model";
import {
  Button,
  EditorComponent,
  ErrorField,
  Htag,
  InputImageComponent,
  Multyselect,
  ResourceIcon,
} from "components";
import { useFormik } from "formik";
import { FormControl } from "@mui/material";

import { categories } from "store/blog/blog.interfaces";
import { useAppDispatch } from "utils/hooks";
import { add_post } from "store/blog/blog-thunk";
import InputTags from "components/Tags/Tags.component";
import {
  CustomSnackbar,
  CustomSnackbarRef,
} from "components/CustomSnackbar/CustomSnackbar.component";

export const validationExtraCreateSchema = yup.object({
  title: yup
    .string()
    .min(3, "Title should be at least 3 characters length")
    .required("Title is required"),
  reading_time: yup.string().required("Unique number is required"),
  content: yup.string().required("Content is required"),
});

export const AddBlogPost = () => {
  const dispatch = useAppDispatch();
  const ref = useRef<CustomSnackbarRef>(null);
  const formik = useFormik({
    initialValues: new BlogPost(),
    validationSchema: validationExtraCreateSchema,

    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("title", values.title);
      formData.append("content", values.content);
      formData.append("reading_time", values.reading_time);

      if (values.image) {
        formData.append("image", values.image);
      } else {
        formData.append("image", "");
      }
      values.categories.map((category: string) => {
        formData.append("categories[]", category);
      });
      values.tags.map((tag: string) => {
        formData.append("tags[]", tag);
      });
      try {
        dispatch(add_post(formData))
          .unwrap()
          .then(() => {
            formik.resetForm();
            ref.current?.refresh();
          });
      } catch (error) {
        console.log(error);
      }
    },
  });
  return (
    <div className={(s.formWrap, st.formContainer)}>
      <Htag tag="h3">
        <ResourceIcon />
        Create new post
      </Htag>
      <form onSubmit={formik.handleSubmit}>
        <div className={s.formField}>
          <Htag tag="h4">Title</Htag>
          <FormControl fullWidth className={s.title}>
            <input
              id="title"
              name="title"
              onBlur={formik.handleBlur}
              value={formik.values.title}
              type="text"
              onChange={formik.handleChange}
              placeholder="Title"
            />
            {formik.touched.title && formik.errors.title && (
              <ErrorField error={formik.errors.title} />
            )}
          </FormControl>
        </div>
        <div className={s.formField}>
          <Htag tag="h4">Reading time: </Htag>
          <FormControl fullWidth className={s.reading_time}>
            <input
              id="reading_time"
              name="reading_time"
              onBlur={formik.handleBlur}
              value={formik.values.reading_time}
              type="text"
              onChange={formik.handleChange}
              placeholder="Reading time"
            />
            {formik.touched.reading_time && formik.errors.reading_time && (
              <ErrorField error={formik.errors.reading_time} />
            )}
          </FormControl>
        </div>
        <div className={s.formField}>
          <Htag tag="h4" className={s.headline}>
            Upload preview image
          </Htag>
          <InputImageComponent
            handleFiles={formik.setFieldValue}
            handleOnBlur={formik.setFieldValue}
            file={formik.values.image}
            id="image"
            name="image"
          />
        </div>
        <div className={s.wyswyg_editorWrap}>
          <EditorComponent
            value={formik.values.content}
            handleChange={(e: any) => formik.setFieldValue("content", e)}
            name="content"
          />

          {formik.touched.content && formik.errors.content && (
            <ErrorField error={formik.errors.content} />
          )}
        </div>
        <div className={st.selects}>
          <Htag tag="h3">Choose categories:</Htag>
          <Multyselect
            label="Categories"
            selectData={formik.values.categories}
            selectArray={categories}
            handleData={(data) => formik.setFieldValue("categories", data)}
          />
        </div>
        <div className={st.tags}>
          <Htag tag="h3">Add tags:</Htag>

          <InputTags
            hashtag={formik.values.tags}
            handleTags={(data) => formik.setFieldValue("tags", data)}
          />
        </div>
        <Button apearance="secondary" type="submit">
          Save
        </Button>
      </form>

      <CustomSnackbar
        message={`Blog post ${formik.values.title} is added successfully!`}
        ref={ref}
      />
    </div>
  );
};
