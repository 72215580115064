import { createAsyncThunk } from "@reduxjs/toolkit";
import { CHILDREN_ONBG } from "../config";
import { IInitState } from "../root-interface";
import { cQuestionnaireForm } from './questionnaires-interface';
import { setToken } from '../userInfo/userInfo-reducer';



export const saveCOnboarding = createAsyncThunk<
  {
    children_data: cQuestionnaireForm;
    success: string;
  },
  cQuestionnaireForm,
  { getState: IInitState; rejectValue: string }
>(
  "@@userEntity/saveCOnBoarding",
  async function (childrenOnBoarding, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + userState.token,
        responseType: "json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(childrenOnBoarding),
    };
    const response = await fetch(CHILDREN_ONBG(), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
		if (userState.token) dispatch(setToken(null));
      }
      return rejectWithValue("Server error");
    } else {
      return (await response.json()) as {
        children_data: cQuestionnaireForm;
        success: string;
      };
    }
  }
);