import { TextField } from "@mui/material";
import { useEffect, useState } from "react";

import s from "./Search.module.css";
import cn from "classnames";
import { SearchIcon } from "..";
import { useAppDispatch } from "../../utils/hooks";

import { get_search_result } from "../../store/search/search-thunk";
import { useLocation, useNavigate } from "react-router-dom";
import { setSearchResult } from "../../store/search/search-reducer";

export const Search = () => {
  const [search, setSearch] = useState("");
  const [showInputField, setShowInputField] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(get_search_result(search))
      .unwrap()
      .then(() => {
        const searchParam = search.replace(/[+()\s-]/g, "+");
        dispatch(setSearchResult(search));
        navigate(`/dashboard/search-results/?search=${searchParam}`);
        
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSearch = (e: any) => {
    setSearch(e.target.value);
  };

  return (
    <form
      onSubmit={onSubmit}
      className={cn(s.searchForm, {
        [s.showInput]: showInputField,
        [s.hideInput]: !showInputField,
      })}
    >
      <TextField
        fullWidth
        id="title"
        name="title"
        placeholder="Search"
        className={cn(s.searchField)}
        value={search}
        onChange={handleSearch}
        onKeyDown={(e) => (e.key === "Enter" ? onSubmit : "")}
      />
      <SearchIcon
        onClick={() => setShowInputField(!showInputField)}
        className={s.searchIcon}
      />
    </form>
  );
};
