import { HtagProps } from "./Htag.props";
import s from "./Htag.module.css";
import cn from "classnames";

export const Htag = ({
  children,
  tag,
  color,
  className,
  ...props
}: HtagProps): JSX.Element => {
  switch (tag) {
    case "h1H": {
      return (
        <h1 className={cn(s.homeHeader, className)} {...props}>
          {children}
        </h1>
      );
    }
    case "h1": {
      return (
        <h1 className={cn(s.h1, className)} {...props}>
          {children}
        </h1>
      );
    }
    case "h2": {
      return (
        <h2 style={{ color: color }} className={cn(s.h2, className)} {...props}>
          {children}
        </h2>
      );
    }
    case "h2standard": {
      return (
        <h2
          style={{ color: color }}
          className={cn(s.h2standard, className)}
          {...props}
        >
          {children}
        </h2>
      );
    }
    case "h3": {
      return (
        <h3 style={{ color: color }} className={cn(s.h3, className)} {...props}>
          {children}
        </h3>
      );
    }
    case "h3_middle": {
      return (
        <h3
          style={{ color: color }}
          className={cn(s.h3_middle, className)}
          {...props}
        >
          {children}
        </h3>
      );
    }

    case "h4": {
      return (
        <h4 style={{ color: color }} className={cn(s.h4, className)} {...props}>
          {children}
        </h4>
      );
    }
    case "h5": {
      return (
        <h5 style={{ color: color }} className={cn(s.h5, className)} {...props}>
          {children}
        </h5>
      );
    }
    default: {
      return <h2>{children}</h2>;
    }
  }
};
