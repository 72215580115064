import {
  Alert,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Snackbar,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  Button,
  DropDownArrowIcon,
  EditorComponent,
  ErrorField,
  Htag,
  InputFileComponent,
  InputImageComponent,
} from "components";
import s from "../SpecialSources.module.css";
import { NavLink, useParams } from "react-router-dom";

import {
  get_single_sresource,
  updateSystemResource,
} from "store/system_resources/system_resource-thunk";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { useFormik } from "formik";
import { validationCreateSchema } from "../validation";
import {
  FileProps,
  IAttach,
} from "store/system_resources/system_resource.interface";
import { milestones } from "store/milestones/milestones-interface";
import { Resource } from "models/Resource.model";

import cn from "classnames";
import InputTags from "components/Tags/Tags.component";

export const EditSRPage = () => {
  const { id } = useParams();
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [currentAttachments, setAttachments] = useState<Partial<FileProps>[]>(
    []
  );
  const [attachmentsOld, setOldAttachments] = useState<Partial<FileProps>[]>(
    []
  );
  const dispatch = useAppDispatch();

  const system_resource = useAppSelector((state) =>
    state.specialResourcesState.resources_db.filter(
      (resource) => resource.id == Number(id)
    )
  )[0];

  const { error } = useAppSelector((state) => state.specialResourcesState);

  const formik = useFormik({
    initialValues: new Resource(),
    validationSchema: validationCreateSchema,
    onSubmit: (values) => {
      if (!id) return;

      const updatedValue = formik.initialValues.formData(values);

      try {
        dispatch(
          updateSystemResource({
            sr_id: id,
            sr_data: updatedValue,
          })
        )
          .unwrap()
          .then(() => {
            setOpenSnackbar(true);
          });
      } catch (error) {
        console.log(error);
      }
    },
  });

  useEffect(() => {
    if (!id) return;

    dispatch(get_single_sresource(id))
      .unwrap()
      .then((resp) => {
        setOldAttachments(resp.attachments);
        formik.setFieldValue("content", resp.content);
        formik.setFieldValue("milestone_id", resp.milestone_id);
        formik.setFieldValue("title", resp.title);
        formik.setFieldValue("unique_number", resp.unique_number);
        if (!resp.reading_time) formik.setFieldValue("reading_time", "");
        formik.setFieldValue("reading_time", resp.reading_time);
        formik.setFieldValue(
          "show_in_dashboard",
          Boolean(resp.show_in_dashboard)
        );
        formik.setFieldValue("image", resp.image);
        formik.setFieldValue("tags", resp.tags);
      });
  }, []);

  const handleAtachments = (attachments: FileProps | IAttach) => {
    setAttachments((old) => [...old, attachments]);

    formik.setFieldValue("attachments", [...currentAttachments, attachments]);
  };

  return (
    <>
      <div className={s.post_header}>
        <Htag tag="h3">Edit resource: "{formik.values.title}" </Htag>
        <Htag tag="h4">Unique number: {formik.values.unique_number} </Htag>
      </div>

      <form onSubmit={formik.handleSubmit}>
        <div className={s.formField}>
          <Htag tag="h4">Title</Htag>
          <FormControl fullWidth className={s.title}>
            <input
              id="title"
              name="title"
              type="text"
              onBlur={formik.handleBlur}
              value={formik.values.title}
              onChange={formik.handleChange}
              placeholder="Title"
            />
            {formik.touched.title && formik.errors.title && (
              <ErrorField error={formik.errors.title} />
            )}
          </FormControl>
        </div>
        <div className={cn(s.formField, s.reading_timeField)}>
          <Htag tag="h4">Reading time: </Htag>
          <FormControl className={s.reading_time}>
            <input
              id="reading_time"
              name="reading_time"
              type="text"
              onBlur={formik.handleBlur}
              value={formik.values.reading_time}
              onChange={formik.handleChange}
              placeholder="Reading time"
            />
            {formik.touched.reading_time && formik.errors.reading_time && (
              <ErrorField error={formik.errors.reading_time} />
            )}
          </FormControl>
          <FormControlLabel
            label="Show on start page"
            className={s.need_journal}
            id="show_in_dashboard"
            control={
              <Checkbox
                checked={formik.values.show_in_dashboard}
                name="show_in_dashboard"
                id="show_in_dashboard"
                onChange={formik.handleChange}
              />
            }
          />
        </div>
        <div className={s.formField}>
          <Htag tag="h4" className={s.headline}>
            Upload preview image
          </Htag>
          <InputImageComponent
            handleFiles={formik.setFieldValue}
            handleOnBlur={formik.setFieldValue}
            file={formik.values.image}
            id="image"
            name="image"
          />
        </div>
        <div className={s.formField}>
          <Htag tag="h4" className={s.headline}>
            Upload attachments
          </Htag>
          <InputFileComponent
            files={attachmentsOld}
            handleFiles={handleAtachments}
            id="EditSR"
          />
        </div>

        <div className={s.wyswyg_editorWrap}>
          <EditorComponent
            value={formik.values.content}
            name="content"
            handleChange={(e) => formik.setFieldValue("content", e)}
          />

          {formik.touched.content && formik.errors.content && (
            <ErrorField error={formik.errors.content} />
          )}
        </div>
        <div>
          <Htag tag="h3">Add tags:</Htag>

          <InputTags
            hashtag={formik.values.tags ? formik.values.tags : []}
            handleTags={(data) => formik.setFieldValue("tags", data)}
          />
        </div>

        {system_resource?.space_id == 1 && (
          <TextField
            variant="outlined"
            name="milestone_id"
            id="milestone_id"
            fullWidth
            select
            className={s.selectStatus}
            label={formik.values.milestone_id === null ? "Task is…" : ""}
            defaultValue={
              formik.values.milestone_id === null
                ? ""
                : formik.values.milestone_id
            }
            InputLabelProps={{ shrink: false, disableAnimation: true }}
            value={
              formik.values.milestone_id === null
                ? ""
                : formik.values.milestone_id
            }
            onChange={formik.handleChange}
            sx={{
              "& .MuiSelect-iconOpen": { transform: "rotate(90deg)" },
              "& .MuiSelect-icon": { top: "16px" },
              "& .Mui-focused": {
                color: "#102D3E",
              },
              "& .Mui-focused.MuiOutlinedInput-root": {
                border: "2px solid #91CCD1;",
              },
            }}
            SelectProps={{
              IconComponent: (props) => <DropDownArrowIcon {...props} />,
            }}
          >
            {milestones.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        )}
        {error &&
          Array.isArray(error) &&
          error.map((item, index) => (
            <ErrorField
              key={index}
              error={`Server error: ${Object.values(item)[0]}`}
            />
          ))}
        {error && typeof error == "string" && <ErrorField error={error} />}
        <p className={s.previewLink}>
          <NavLink
            target="_blank"
            to={`/admin-dashboard/system-resources/edit-resource/${id}/preview`}
          >
            Preview
          </NavLink>
        </p>

        <Button apearance="secondary" type="submit">
          Save
        </Button>
      </form>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          System resource "{system_resource?.title}" is updated!
        </Alert>
      </Snackbar>
    </>
  );
};
