import { Htag, Notification } from "components";

import { useAppDispatch, useAppSelector } from "utils/hooks";
import s from "./SpaceTemplate.module.css";
import { useParams } from "react-router-dom";
import { getSpace, loadSingleSpace } from "store/spaces/spaces-actions";
import { useEffect, useMemo, useState } from "react";
import { loadTickets } from "store/tickets/tickets-thunk";
import { BoardsComponent } from "./Boards/Boards.component";
import { TemplatesComponent } from "./Templates/Templates.component";
import { ResourcesListComponent } from "./SResources/ResourcesList.component";
import _ from "lodash";
import { FullModalStyled } from "components/FullWidthModal/FullModal.component";
import { CQuestionnaire } from "./CQuestionnaire/CQuestionnaire.component";
import { setToInit } from "store/questionnaires/questionnaires.reducer";
import { setChildrenResources } from "store/spaces/spaces-reducer";
import { IMemory } from 'store/memory/memory-thunk';

export const SpacePage = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const [openQuestionnaire, setOpenQuestionnaire] = useState(false);

  const milestoneMemory = useAppSelector((state) =>
    state.userState.user.memory.filter(
      (item: IMemory) => item.name == `Notification_Children_${id}`
    )
  );
  const CQuestionnaireEntityLength = useAppSelector((state) => {
    if (!state.userState.user.questionnaires) return true;
    return _.isEmpty(state.userState.user.questionnaires.childrenQuestionnary);
  });

  const living_property = useAppSelector(
    (state) =>
      state.userState.user.questionnaires.generalQuestionnary?.living_property
  );

  const account_status = useAppSelector(
    (state) => state.accountState.subscription.status
  );

  const space = useAppSelector((state) =>
    getSpace(state.allSpaces.spaces, Number(id))
  );
  const activeStep = useAppSelector(
    (state) => state.questionnairesState.activeStep
  );

  const shouldOpenQuestionnaire = useMemo(() => {
    if (account_status !== "active" || !living_property) return false;

    return CQuestionnaireEntityLength;
  }, [account_status, id]);

  const handleCloseQuestionnaire = () => {
    setOpenQuestionnaire(false);
    dispatch(setToInit());
  };

  useEffect(() => {
    setOpenQuestionnaire(shouldOpenQuestionnaire);
  }, [shouldOpenQuestionnaire]);

  useEffect(() => {
    dispatch(loadSingleSpace(Number(id)))
      .unwrap()
      .then((resp) => {
        setChildrenResources(resp);
      });
  }, []);

  useEffect(() => {
    dispatch(loadTickets(Number(id)));
  }, []);

  return (
    <>
      <Htag tag="h2">Children Space</Htag>
      {milestoneMemory[0]?.value !== "true" && (
        <Notification name={`Children_${id}`}>
          This is your space for supporting your children, sorting out your
          childcare and parenting arrangements. All the actions and guides have
          been designed by our experts to minimise the impact of divorce on your
          family. We have experts in family law, finances and child psychology.
        </Notification>
      )}
      <BoardsComponent />

      <section className={s.guidesWrap}>
        <header className={s.resources_header}>
          <Htag tag="h2standard">Guides</Htag>
        </header>{" "}
        {space?.space_resources && (
          <ResourcesListComponent
            showTgas={true}
            show_in_row={4}
            resources={space.space_resources}
          />
        )}
      </section>
      <section className={s.guidesWrap}>
        <TemplatesComponent />
      </section>

      <FullModalStyled
        color={activeStep == 0 ? "dark" : "light"}
        open={openQuestionnaire}
        close={() => setOpenQuestionnaire(false)}
      >
        <CQuestionnaire close={handleCloseQuestionnaire} />
      </FullModalStyled>
    </>
  );
};
