import { NavLink, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { useEffect, useState } from "react";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ReactHtmlParser from "html-react-parser";
import { get_system_single_ticket } from "store/system_tickets/system-ticket-thunk";
import { ThreeDots } from "react-loader-spinner";
import s from "../SpecialSources.module.css";
import { ClockIcon, Htag, ResourceIcon, TemplateIcon } from "components";
import cn from "classnames";

import r from "components/ArticleContent/ArticleContent.module.css";
import { FileProps } from "store/system_resources/system_resource.interface";

import { downloadAttach } from "controlls/tickets-controll";

export const PreviewTicket = () => {
  const { ticketId } = useParams();
  const { loading } = useAppSelector((state) => state.tickets);

  const dispatch = useAppDispatch();
  const id = ticketId?.slice(ticketId.indexOf("=") + 1);
  const [title, setTitle] = useState("");
  const [status, setStatus] = useState(0);

  const [convertedContent, setConvertedContent] = useState("");
  const [attachments, setAttachments] = useState([]);
  const ticket = useAppSelector((state) =>
    state.specialTicketsState.tickets_db.filter(
      (ticket) => ticket.id == Number(id)
    )
  )[0];

  useEffect(() => {
    if (!id) return;
    if (
      !ticket ||
      !Object.prototype.hasOwnProperty.call(ticket, "description")
    ) {
      dispatch(get_system_single_ticket(Number(id)))
        .unwrap()
        .then((resp) => {
          setConvertedContent(resp.description && resp.description);

          setTitle(resp.title);
          setStatus(resp.status);
          setAttachments(resp.attachments);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setConvertedContent(ticket.description && ticket.description);

      setTitle(ticket.title);
      setStatus(ticket.status);
    }
  }, [ticket]);

  useEffect(() => {
    console.log(ticket);
  }, [ticket]);

  return (
    <>
      {loading || !ticket ? (
        <ThreeDots wrapperClass={s.loader} />
      ) : (
        <div className={cn(s.ticketViewWrap, r.contentWrap)}>
          <header className={s.ticketViewHeader}>
            <Htag tag="h2" className={s.main_title}>
              {title}
            </Htag>

            <div className={cn(s.extraInfo, r.meta)}>
              <span>
                {ticket?.reading_time && (
                  <>
                    <ClockIcon /> {ticket?.reading_time}
                  </>
                )}
              </span>
              <span className={r.templatesMark}>
                {attachments && attachments.length > 0 && (
                  <>
                    <TemplateIcon /> Templates
                  </>
                )}
              </span>
            </div>
          </header>
          <div className={s.ticket_desc}>
            <Htag tag="h4">
              <ResourceIcon /> Description
            </Htag>
            <div className={cn(s.ticket_desc_content)}>
              {ReactHtmlParser(convertedContent)}
            </div>
          </div>
          {attachments && attachments.length > 0 && (
            <div className={r.templates}>
              <h3>
                <TemplateIcon /> Templates to help you
              </h3>
              {attachments.map((template: FileProps, index: number) => (
                <div
                  onClick={() => {
                    if (ticket.id) {
                      downloadAttach(template.id, ticket.id, template.name);
                    }
                  }}
                  className={r.templateItem}
                  key={index}
                >
                  <FileDownloadOutlinedIcon />
                  {template.name}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
      <NavLink
        to=".."
        relative="path"
        className={cn(s.backBtn, "hover-underline-animation_back")}
      >
        {"<"} Back
      </NavLink>
    </>
  );
};
