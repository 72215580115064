import axios from "axios";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import s from "./Payment.module.css";
import cn from "classnames";
import { StripeProduct } from "store/prices/price-interface";
import { addBasicProduct, addJointProduct } from "store/prices/prices-reducer";
import { Radio, RadioGroup } from "@mui/material";
import { Button, CheckIcon, ErrorField, Htag } from "components";
import { openLoginModal } from "store/login/login-reducer";
import guaranty_mark from "./Guarantee.png";
export const PaymentComponent = () => {
  const month = useAppSelector((state) => state.subscriptions.month);

  const halfYear = useAppSelector((state) => state.subscriptions.halfYear);

  const dispatch = useAppDispatch();

  const [apiError, setApiError] = useState("");
  const userToken = useAppSelector((state) => state.userState.token);
  const [priceValue, setPriceValue] = useState(halfYear.price_id);
  const { email, id } = useAppSelector((state) => state.userState.user);
  const handleOpenLoginForPlan = () => dispatch(openLoginModal(true));

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/stripe/list-prices`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        responseType: "json",
      })
      .then((res) => {
        res.data.result.map((x: StripeProduct) => {
          const product = {
            price_id: x.id,
            name: x.name,
            price: x.unit_amount_decimal.slice(0, -2),
            type: x.type,
          };

          if (x.name.toLowerCase().includes("1")) {
            dispatch(addBasicProduct(product));
          }
          if (x.name.toLowerCase().includes("6")) {
            dispatch(addJointProduct(product));
            setPriceValue(product.price_id);
          }
        });
      })
      .catch((error) => {
        setApiError(error.response.message);
      });
  }, []);

  const buyPlan = () => {
    if (!userToken) {
      handleOpenLoginForPlan();
    } else {
      axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}/stripe/create-payment-link`,
          { ids: [priceValue] },
          {
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + userToken,
            },
            responseType: "json",
          }
        )
        .then((res) => {
          window.location.replace(
            `${res.data.result.url}?client_reference_id=${id}&prefilled_email=${email}`
          );
        })
        .catch((error) => {
          if ([401, 403].includes(error.response.status)) {
            localStorage.removeItem("userToken");
          }
          if (error.response.data?.errors) {
            if (error.response.data?.errors[0]["ids.0"]) {
              setApiError("You need to choose a plan");
            }
          } else if (!error.result) {
            setApiError("You already bought a subscription");
          } else {
            setApiError(error.response.message);
          }
        });
    }
  };
  const handlePrice = (e: string) => {
    setPriceValue(e);
  };
  return (
    <>
      <div className={s.plans}>
        <RadioGroup row name="not_foreigner" className={s.radioboxes}>
          <div
            className={cn(s.planItem, s.halfYearPlan, {
              [s.active]: priceValue == halfYear.price_id,
            })}
            onClick={() => handlePrice(halfYear.price_id)}
          >
            <Radio color="default" checked={priceValue == halfYear.price_id} />
            <div className={s.priceTitle}>
              <h3>6-month membership</h3>
              <p>
                Pay £{halfYear.price} for 6 months today. Renews every 6 months.
                Cancel any time.
              </p>
            </div>
            <div className={s.priceValue}>
              <s>£{month.price}</s>
              <span> £{Number(halfYear.price) / 6} </span>a month
            </div>
          </div>
          <div
            className={cn(s.planItem, s.monthPlan, {
              [s.active]: priceValue == month.price_id,
            })}
            onClick={() => handlePrice(month.price_id)}
          >
            <Radio color="default" checked={priceValue == month.price_id} />

            <div className={s.priceTitle}>
              <h3>Monthly Membership</h3>
              <p>Renews every month. Cancel anytime.</p>
            </div>
            <div className={s.priceValue}>
              <span>£{month.price}</span> a month
            </div>
          </div>
        </RadioGroup>

        <Button
          apearance="secondary"
          className={cn(s.buyPlan, s.buyPlan_basic)}
          onClick={() => buyPlan()}
        >
          Pay now
        </Button>
        {apiError && <ErrorField error={apiError} />}
      </div>
      <section className={cn(s.backGuaranty)}>
        <img src={guaranty_mark} alt="" />
        <div className={s.guaranty_content}>
          <Htag tag="h3">7-Day Money-Back Guarantee</Htag>
          <p>
            If you're not happy with your membership, tell us within 7 days for
            a full refund.
          </p>
        </div>
      </section>
      <section className={cn(s.includesList)}>
        <Htag tag="h3">All memberships include</Htag>
        <ul>
          <li>
            <CheckIcon /> Personalised divorce pathway
          </li>
          <li>
            <CheckIcon />
            Expert legal and practical guidance tailored to your circumstances
          </li>
          <li>
            <CheckIcon />
            Divorce templates by legal experts
          </li>
          <li>
            <CheckIcon />
            Legal guidance about your children
          </li>
          <li>
            <CheckIcon />
            Advice and scripts from child and relationship experts
          </li>
          <li>
            <CheckIcon />
            15 minute one-to-one introductory call with a Divorce Expert
          </li>
        </ul>
      </section>
    </>
  );
};
