import { createAsyncThunk } from "@reduxjs/toolkit";
import {
	DELETE_MEMBER,
  GET_SUBSCRIBER,
  GET_SUBSCRIBERS_LIST,
  GET_WAITING_LIST,
} from "../config";
import { IInitState } from "../root-interface";
import {
  IGetRegUsersData,
  IMember,
  IgetUsersApiRequest,
  MemberType,
} from "./waitingList.interface";
import { setToken } from "../userInfo/userInfo-reducer";
import { getQueryString } from "utils/utils";

export const get_waiting_list = createAsyncThunk<
  IMember[],
  undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@waitingList/gettingWaitingList",
  async function (_, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "GET",
      headers: {
        Accept: "application/json",
        responseType: "json",
        Authorization: "Bearer " + userState.token,
      },
    };
    const response = await fetch(GET_WAITING_LIST(), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
        if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as IMember[];
    }
  }
);

export const get_subscribers_list = createAsyncThunk<
  IGetRegUsersData,
  IgetUsersApiRequest,
  { getState: IInitState; rejectValue: string }
>(
  "@@waitingList/gettingSubscribers",
  async function (data, { getState, rejectWithValue, dispatch }) {
    const { page, limit } = data;
    const { userState } = getState() as IInitState;

    const header = {
      method: "GET",
      headers: {
        Accept: "application/json",
        responseType: "json",
        Authorization: "Bearer " + userState.token,
      },
    };

    const response = await fetch(
      getQueryString(
        `${GET_SUBSCRIBERS_LIST()}?page=${page}&limit=${limit}`,
        data
      ),
      header
    );

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
        if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as IGetRegUsersData;
    }
  }
);

export const get_subscriber = createAsyncThunk<
  MemberType,
  number,
  { getState: IInitState; rejectValue: string }
>(
  "@@waitingList/gettingSubscribers",
  async function (id, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "GET",
      headers: {
        Accept: "application/json",
        responseType: "json",
        Authorization: "Bearer " + userState.token,
      },
    };
    const response = await fetch(GET_SUBSCRIBER(id), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
        if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as MemberType;
    }
  }
);

export const deleteMember = createAsyncThunk<
  { success: string },
  number,
  { getState: IInitState; rejectValue: string }
>(
  "@@members/deleteMember",
  async function (id, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;
    const header = {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + userState.token,
        responseType: "json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ user_id: id }),
    };

    const response = await fetch(DELETE_MEMBER(), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
        if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          if (result.errors) {
            return rejectWithValue(result.errors[0]);
          }
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as { success: string };
    }
  }
);
