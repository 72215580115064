import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  CREATE_POST,
  DELETE_POST,
  GET_ALL_POSTS,
  GET_RECENT_POSTS,
  GET_SINGLE_POST,
  UPDATE_POST,
} from "../config";
import { IInitState } from "../root-interface";
import { IGetPostsData, Post } from "./blog.interfaces";
import { setToken } from '../userInfo/userInfo-reducer';

export const add_post = createAsyncThunk<
  { success: string },
  FormData,
  { getState: IInitState; rejectValue: string }
>(
  "@@blog/adding",
  async function (data, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + userState.token,
      },
      body: data,
    };
    const response = await fetch(CREATE_POST(), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
        if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as { success: string };
    }
  }
);

export const get_all_posts = createAsyncThunk<
  IGetPostsData,
  { page: number; limit: number },
  { getState: IInitState; rejectValue: string }
>("@@blog/getting", async function (data, { rejectWithValue,  }) {
  const { page, limit } = data;

  const header = {
    method: "GET",
    headers: {
      Accept: "application/json",
      responseType: "json",
    },
  };
  const response = await fetch(GET_ALL_POSTS(page, limit), header);

  if (!response.ok) {
    if ([401, 403].includes(response.status)) {
      localStorage.removeItem("userToken");

    }
    return response
      .text()
      .then((result) => JSON.parse(result))
      .then((result) => {
        return rejectWithValue(result.message);
      });
  } else {
    return (await response.json()) as IGetPostsData;
  }
});

export const get_recent_posts = createAsyncThunk<
  Post[],
  number,
  { getState: IInitState; rejectValue: string }
>(
  "@@blog/gettingRecentPosts",
  async function (post_id, { getState, rejectWithValue }) {
    const header = {
      method: "GET",
      headers: {
        Accept: "application/json",
        responseType: "json",
      },
    };
    const response = await fetch(GET_RECENT_POSTS(post_id), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as Post[];
    }
  }
);
export const get_all_user_posts = createAsyncThunk<
  IGetPostsData,
  { page: number; limit: number },
  { getState: IInitState; rejectValue: string }
>("@@blog/gettingUP", async function (data, { getState, rejectWithValue }) {
  const { page, limit } = data;

  const header = {
    method: "GET",
    headers: {
      Accept: "application/json",
      responseType: "json",
    },
  };
  const response = await fetch(GET_ALL_POSTS(page, limit), header);

  if (!response.ok) {
    if ([401, 403].includes(response.status)) {
      localStorage.removeItem("userToken");
    }
    return response
      .text()
      .then((result) => JSON.parse(result))
      .then((result) => {
        return rejectWithValue(result.message);
      });
  } else {
    return (await response.json()) as IGetPostsData;
  }
});

export const deletePost = createAsyncThunk<
  { page_id: number; succes: string },
  string,
  { getState: IInitState; rejectValue: string }
>("@@blog/deletePost", async function (post_id, { getState, rejectWithValue }) {
  const { userState } = getState() as IInitState;

  const header = {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + userState.token,
      responseType: "json",
    },
  };
  const response = await fetch(DELETE_POST(post_id), header);

  if (!response.ok) {
    if ([401, 403].includes(response.status)) {
      localStorage.removeItem("userToken");
    }
    return response
      .text()
      .then((result) => JSON.parse(result))
      .then((result) => {
        if (result.errors) {
          return rejectWithValue(result.errors[0]);
        }
        return rejectWithValue(result.message);
      });
  } else {
    return (await response.json()) as { page_id: number; succes: string };
  }
});

export const get_single_post = createAsyncThunk<
  Post,
  string,
  { getState: IInitState; rejectValue: string }
>("@@blog/singlePost", async function (id, { getState, rejectWithValue }) {
  const header = {
    method: "GET",
    headers: {
      Accept: "application/json",
      responseType: "json",
    },
  };
  const response = await fetch(GET_SINGLE_POST(id), header);

  if (!response.ok) {
    if ([401, 403].includes(response.status)) {
      localStorage.removeItem("userToken");
    }
    return response
      .text()
      .then((result) => JSON.parse(result))
      .then((result) => {
        if (result) return rejectWithValue(result.error);
        else return rejectWithValue("Server error");
      });
  } else {
    return (await response.json()) as Post;
  }
});

export const updatePost = createAsyncThunk<
  { success: string; blog: Post },
  {
    post_id: string;
    data: {
      title: string;
      content: string;
      reading_time: string;
      tags: string[];
      image: Blob | null | string;
      categories: string[];
    };
  },
  { getState: IInitState; rejectValue: string }
>(
  "@@blog/updatePost",
  async function ({ post_id, data }, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;

    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("content", data.content);
    formData.append("reading_time", data.reading_time);
    data.image
      ? formData.append("image", data.image)
      : formData.append("image", "");

    if (!data.tags.length) formData.append("tags", "");
    data.tags.map((tag: string) => {
      formData.append("tags[]", tag);
    });
    if (!data.categories.length) formData.append("categories", "");
    data.categories.map((tag: string) => {
      formData.append("categories[]", tag);
    });

    const header = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + userState.token,
      },
      body: formData,
    };

    const response = await fetch(UPDATE_POST(post_id), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
		if (userState.token) dispatch(setToken(null));
      }

      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          if (result.errors) {
            return rejectWithValue(result.errors);
          }
          return rejectWithValue(result.message);
        });
    } else {
      return (await response.json()) as {
        success: string;
        blog: Post;
      };
    }
  }
);
