import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGeneralQuestionnaryState } from "./generalOnboarding-interface";
import { resetToDefault } from "../login/login-reducer";
import { saveGOnboarding } from "./generalOnboarding-actions";

const initialState: IGeneralQuestionnaryState = {
  generalOnboarding: {
    years_of_marriage: "",
    years_before_marriage: "",
    living_property: null,
    has_children: "",
    not_foreigner: "",
    home_property: null,
    started_process: null,
    place_of_marrige: null,
    other_properties: null,
    nuptial_agreement: null,
    inherited_wealth: null,
    considerable_wealth: null,
    trust_beneficiary: null,
    debts: [
      {
        value: false,
        name: "bank_account",
        label: "Bank account / ISA",
      },
      {
        value: false,
        name: "shares",
        label: "Shares",
      },
      {
        value: false,
        name: "cryptocurrency",
        label: "Cryptocurrency",
      },
      {
        value: false,
        name: "business",
        label: "A business",
      },
      {
        value: false,
        name: "car",
        label: "Car",
      },
      {
        value: false,
        name: "life_insurance",
        label: "Life insurance policy",
      },
      {
        value: false,
        name: "owed_money",
        label:
          "Money owed to you (eg you might have loaned your friend some money)",
      },
      {
        value: false,
        name: "money_owe",
        label:
          "Money you owe (this might be money owed to a friend or family member but could also be a credit card, a store card, a bank loan or a hire purchase agreement on a car)",
      },
    ],
    pensions: null,
  },
  activeStep: 0,
  server_error: "",
  loading: false,
};

const generalOnBoardingState = createSlice({
  name: "@@generalOnboarding",
  initialState,
  reducers: {
    setBoardingStep: (state, action: PayloadAction<number>) => {
      state.activeStep = action.payload + 1;
    },
    setPrevBoardingStep: (state, action) => {
      state.activeStep =
        action.payload > 0 ? action.payload - 1 : (action.payload = 0);
    },
    setOnboarding: (state, action) => {
      state.generalOnboarding = action.payload;
    },
    addServerError: (state, action) => {
      state.server_error = action.payload;
    },
    setInterviewToInit: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(saveGOnboarding.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(saveGOnboarding.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(resetToDefault, () => {
      return initialState;
    });
  },
});

export const {
  setBoardingStep,
  setPrevBoardingStep,
  setOnboarding,
  addServerError,
  setInterviewToInit,
} = generalOnBoardingState.actions;

export default generalOnBoardingState.reducer;
