import { FormControl, InputLabel } from "@mui/material";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import s from "./caseStudySummary.module.css";
import { AssetsIcon, ClockIcon, DivisonIcon, Htag, IncomeIcon, ParentsIcon } from "../../components";

import { ISummary } from "../../store/caseStudy/caseStudy.inderface";
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#ffffff" : "#1A2027",
    border: "1px solid",
    borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
    fontSize: 16,
    width: "100%",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.

    "&:focus": {
      borderColor: theme.palette.primary.main,
    },
  },
}));

interface ISummaryItem  {
	title: string;
	icon: JSX.Element
}

export const summaryCSData: ISummaryItem[] = [
  {
    title: "Family home",
    icon: <ParentsIcon />,
  },
  {
    title: "Income",
    icon: <IncomeIcon />,
  },
  {
    title: "Assets",
    icon: <AssetsIcon />,
  },

  {
    title: "Pensions",
    icon: <DivisonIcon />,
  },
];

export const CSSummary = ({
  handleSummary,
  summary,
}: {
  handleSummary: (data: ISummary[]) => void;
  summary: ISummary[];
}) => {
  const handleInput = (e: any) => {
    const updatedArr = summary.filter((item) => item.id !== e.target.id);

    handleSummary([
      ...updatedArr,
      {
        id: e.target.id,
        value: e.target.value,
      },
    ]);
  };

  const setSomeValue = (index: number) => {
    const getValue = summary.find((item) => item.id == index);
    if (getValue) return getValue?.value;
    return "";
  };

  return (
    <>
      <Htag tag="h4">Add summary</Htag>
      <div className={s.summary}>
        {summaryCSData.map((item, index) => (
          <FormControl key={index} fullWidth variant="standard">
            <InputLabel className={s.label} shrink htmlFor="bootstrap-input">
              {item.title}
            </InputLabel>

            <BootstrapInput
              onChange={handleInput}
              value={setSomeValue(index)}
              id={index.toString()}
            />
          </FormControl>
        ))}
      </div>
    </>
  );
};
