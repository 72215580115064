import { createAsyncThunk } from "@reduxjs/toolkit";
import { DELETE_ATTACH, DELETE_TICKET_ATTACH } from "../config";
import { IInitState } from "../root-interface";
import { setToken } from "../userInfo/userInfo-reducer";

export enum ATTACH_TYPE {
	AGENDA,
	LEGAL,
	LETTER,
	MAIL,
	SCRIPT,
	SPREADSHEET
}

export const attachType: readonly string[] = [
  "Agenda",
  "Legal",
  "Letter",
  "Mail",
  "Script",
  "Spreadsheet",
];

export const deleteAttach = createAsyncThunk<
  number,
  number,
  { getState: IInitState; rejectValue: string }
>(
  "@@attachments/deleteAtach",
  async function (id, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + userState.token,
        responseType: "json",
      },
    };
    const response = await fetch(DELETE_ATTACH(id), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
        if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          if (result.errors) {
            return rejectWithValue(result.errors[0]);
          }
          return rejectWithValue(result.message);
        });
    } else {
      return id;
    }
  }
);

export const deleteTicketAttach = createAsyncThunk<
  number,
  number,
  { getState: IInitState; rejectValue: string }
>(
  "@@attachments/deleteTicketAtach",
  async function (id, { getState, rejectWithValue, dispatch }) {
    const { userState } = getState() as IInitState;

    const header = {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + userState.token,
        responseType: "json",
      },
    };
    const response = await fetch(DELETE_TICKET_ATTACH(id), header);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("userToken");
        if (userState.token) dispatch(setToken(null));
      }
      return response
        .text()
        .then((result) => JSON.parse(result))
        .then((result) => {
          if (result.errors) {
            return rejectWithValue(result.errors[0]);
          }
          return rejectWithValue(result.message);
        });
    } else {
      return id;
    }
  }
);
